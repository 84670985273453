import { constants } from "./../constants/constants";

//Create filter query
export function createFilterQuery(filter) {
  let query = [];
  Object.keys(filter).map(function(key) {
    if (filter[key].filter !== constants.GRID_FILTER_BLANK_STRING) {
      query[key] = filter[key].filter;
    }
  });

  return query;
}

//Filter space and underscore from given string
function filterValue(str) {
  return str.replace(/[_-]/g, " ");
}

//Convert string to title case
export function titleCase(str) {
  if (str) {
    let filteredValue = filterValue(str);
    const titleCase = filteredValue
      .toLowerCase()
      .split(" ")
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    return titleCase;
  }
  return "";
}
