import { createReducer } from "reduxsauce";
import Types from "../constants/actionTypes";

export const INITIAL_STATE = {
  inventoryLogDetail: {},
  isLoading: false
};

//Update inventory reducer starts here
export const updateInventoryInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const updateInventorySuccess = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const updateInventoryFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

//Inventory Log fetch reducer starts here
export const fetchInventoryLogDetailInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const fetchInventoryLogDetailSuccess = (
  state = INITIAL_STATE,
  { data: data }
) => {
  return { ...state, inventoryLogDetail: data, isLoading: false };
};

export const fetchInventoryLogDetailFail = (
  state = INITIAL_STATE,
  { data: detail }
) => {
  return { ...state, detail, isLoading: true };
};

//define handlers
export const HANDLERS = {
  [Types.ACTION_INVENTORY_UPDATE_INIT]: updateInventoryInit,
  [Types.ACTION_INVENTORY_UPDATE_SUCCESS]: updateInventorySuccess,
  [Types.ACTION_INVENTORY_UPDATE_FAIL]: updateInventoryFail,
  [Types.ACTION_INVENTORY_LOG_INIT]: fetchInventoryLogDetailInit,
  [Types.ACTION_INVENTORY_LOG_SUCCESS]: fetchInventoryLogDetailSuccess,
  [Types.ACTION_INVENTORY_LOG_FAIL]: fetchInventoryLogDetailFail
};

export default createReducer(INITIAL_STATE, HANDLERS);
