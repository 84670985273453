import React, { Component } from "react";

class LinkRenderer extends Component {
  invokeParentMethod = componentParent => {
    const name = componentParent.getUniqueIdName();
    return componentParent.redirectToDetailsPage(this.props.data[name]);
  };

  render() {
    const { componentParent } = this.props.context;
    const name = componentParent.getUniqueIdName();
    return (
      <a href="# " onClick={() => this.invokeParentMethod(componentParent)}>
        {this.props.data[name]}
      </a>
    );
  }
}

export default LinkRenderer;
