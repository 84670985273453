export const constants = {
  LOGIN_REQUEST_CLIENT: "c2c",
  LOGIN_REQUEST_TOKEN_TYPE: "jwt",
  GRID_FILTER_BLANK_STRING: "",
  FORM_ACTION_SAVE: "Save",
  FORM_ACTION_UPDATE: "Update"
};

export const carSourceConstants = {
  CUSTOMER_CARE: "CUSTOMER_CARE",
  B2C: "B2C",
  GS_CAR: "GS_CAR",
  MANUAL_UPLOAD: "MANUAL_UPLOAD",
  C2C_DEALER_CAR: "C2C_DEALER_CAR"
};

export const orderStateConstants = {
  DRAFT: "DRAFT",
  PUBLISH: "PUBLISH",
  BOOKED: "BOOKED",
  SOLD: "SOLD",
  ARCHIVE: "ARCHIVE"
};

export const backgroundCheckStatusConstants = {
  PENDING: "PENDING",
  ASSURED: "ASSURED",
  NON_ASSURED: "NON_ASSURED",
  DELIST: "DELIST"
};

export const dealerTypeConstants = {
  DEALER_TYPE_GS_CAR: "GS_CAR",
  DEALER_TYPE_C2C_DEALER_CAR: "C2C_DEALER_CAR"
};

export const agentStatusConstants = {
  PENDING: "PENDING",
  ASSIGNED_TO_FINANCE: "ASSIGNED_TO_FINANCE",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  ASSIGNED_TO_VENDOR: "ASSIGNED_TO_VENDOR"
};

export const legalStatusConstants = {
  INDIVIDUAL: "INDIVIDUAL",
  COMPANY: "COMPANY",
  PARTNERSHIP: "PARTNERSHIP",
  PROPRIETORSHIP: "PROPRIETORSHIP",
  HUF: "HUF",
  OTHERS: "OTHERS"
};

export const documentConstants = {
  NON_GST_CERTIFICATE: "NON_GST_CERTIFICATE",
  GST_CERTIFICATE: "GST_CERTIFICATE",
  PAN_CARD: "PAN_CARD",
  AADHAR_CARD: "AADHAR_CARD",
  DECLARATION_FORM: "DECLARATION_FORM",
  BANK_DOCUMENT: "BANK_DOCUMENT",
  EMPANELMENT_FORM: "EMPANELMENT_FORM"
};

export const inventoryStatusConstants = {
  IN_TRANSIT: "IN_TRANSIT",
  STOCK_IN: "STOCK_IN",
  STOCK_OUT: "STOCK_OUT",
  NEW_CAR: "NEW_CAR"
};

export const stockOutReasonsConstants = {
  SOLD: "SOLD",
  OUT_FOR_MAINTENANCE: "OUT_FOR_MAINTENANCE",
  C2C_STORE_MOVEMENT: "C2C_STORE_MOVEMENT"
};
