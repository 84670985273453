import axios from "axios";
import { toast } from "react-toastify";

const ENABLE_AUTH = true;
// Encode and create query param string
function encodeQueryString(params) {
  const ret = [];
  for (let p in params) {
    ret.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
  }
  return ret.join("&");
}

//add query params to given URL
const addQueryParamsToUrl = (url, params) => {
  const isEmpty =
    Object.keys(params).length === 0 && params.constructor === Object;
  return isEmpty ? url : url + (params ? "?" + encodeQueryString(params) : "");
};

// Handle known exception during HTTP request
function handleException(result) {
  if (result && result.status === 401) {
    localStorage.removeItem("c2cUser");
    toast.error("Unauthorized Access. Please login!");
    setTimeout(() => window.location.reload(), 2000);
  } else if (result && result.status !== 200) {
    let msg = "";
    if (result.errors) {
      msg = result.errors[0]["message"];
    }

    if (!msg) {
      msg = result.message;
    }

    if (!msg) {
      msg = "Something went wrong!";
    }

    toast.error(msg);
  }
}

//HTTP Get method
export function get(url, params = {}) {
  const fullUrl = addQueryParamsToUrl(url, params);
  const contentTypeHeader = { "Content-Type": "application/json" };
  //Set auth header
  const authenticationHeader = ENABLE_AUTH ? createAuthenticationHeader() : {};
  //Set headers
  const axiosConfig = {
    headers: { ...authenticationHeader.headers, ...contentTypeHeader },
    crossdomain: true
  };

  const response = axios
    .get(fullUrl, axiosConfig)
    .then(result => {
      handleException(result.data);
      return result.data;
    })
    .catch(error => {
      console.log("Fetch error: ", error);
      handleException(error.response);
      return [];
    });

  return response;
}

// HTTP POST request handler
export function post(url, data = {}, params = {}) {
  const fullUrl = addQueryParamsToUrl(url, params);
  const contentTypeHeader = { "Content-Type": "application/json" };
  //Set auth header
  const authenticationHeader = ENABLE_AUTH ? createAuthenticationHeader() : {};
  //Set headers
  const axiosConfig = {
    headers: { ...authenticationHeader.headers, ...contentTypeHeader },
    crossdomain: true
  };

  const response = axios
    .post(fullUrl, data, axiosConfig)
    .then(result => {
      handleException(result.data);
      return result.data;
    })
    .catch(error => {
      if (error.response) {
        console.log("POST error first: ", error.response.data);
        handleException(error.response.data);
      } else {
        console.log("POST error second: ", error);
        handleException(error);
        return [];
      }
    });

  return response;
}

// HTTP POST request handler
export function put(url, data = {}, params = {}) {
  const fullUrl = addQueryParamsToUrl(url, params);
  const contentTypeHeader = { "Content-Type": "application/json" };
  //Set auth header
  const authenticationHeader = ENABLE_AUTH ? createAuthenticationHeader() : {};
  //Set headers
  const axiosConfig = {
    headers: { ...authenticationHeader.headers, ...contentTypeHeader },
    crossdomain: true
  };

  const response = axios
    .put(fullUrl, data, axiosConfig)
    .then(result => {
      handleException(result.data);
      return result.data;
    })
    .catch(error => {
      if (error.response) {
        console.log("PUT error: ", error.response.data);
        handleException(error.response.data);
      } else {
        console.log("PUT error: ", error);
        handleException(error);
        return [];
      }
    });

  return response;
}

//Create Authentication JWT headers
function createAuthenticationHeader() {
  const authObj = getUserFromLocalStorage();
  return {
    headers: {
      Authorization: "Bearer " + authObj.Authorization
    }
  };
}

// HTTP POST file upload request handler
export function fileUpload(url, params = {}, fileObject) {
  let data = new FormData();
  data.append("file", fileObject, fileObject.name);
  return post(url, data, params);
}

//Get user object from local storage
function getUserFromLocalStorage() {
  const user = localStorage.getItem("c2cUser");
  const c2cUser = JSON.parse(user);
  let token = "";
  if (c2cUser) {
    token = c2cUser.token;
  }

  return {
    Authorization: token
  };
}
