import { createReducer } from "reduxsauce";
import Types from "../constants/actionTypes";

export const INITIAL_STATE = {
  order: {},
  despositions: {},
  seller: {},
  inspection: {},
  c2bOrder: {},
  sellerInspectionDetail: {},
  isLoading: false
};

export const fetchOrderDetailInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const fetchOrderDetailSuccess = (state = INITIAL_STATE, action) => {
  const {
    data: { order, despositions, seller }
  } = action;
  return {
    ...state,
    order,
    despositions,
    seller,
    isLoading: false
  };
};

export const fetchOrderDetailFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

//update order state
export const updateOrderStateInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const updateOrderStateSuccess = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const updateOrderStateFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

//update order details
export const updateOrderInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

// export const updateOrder = (state = INITIAL_STATE) => {
//   return { ...state, isLoading: true };
// };

export const updateOrderSuccess = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const updateOrderFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const fetchOrderInspectionDetail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const fetchOrderInspectionDetailSuccess = (
  state = INITIAL_STATE,
  action
) => {
  const { data: inspection } = action;

  return {
    ...state,
    inspection: { ...inspection },
    isLoading: false
  };
};

export const fetchOrderInspectionDetailFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

//Fetch c2b order details
export const fetchC2bOrderDetailInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};
export const fetchC2bOrderDetailSuccess = (state = INITIAL_STATE, action) => {
  const { data: c2bOrder } = action;
  return {
    ...state,
    c2bOrder,
    isLoading: false
  };
};
export const fetchC2bOrderDetailFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

//Add b2c order
export const addB2cOrderInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};
export const addB2cOrderSuccess = (state = INITIAL_STATE, action) => {
  const { data: c2bOrder } = action;
  return {
    ...state,
    isLoading: false
  };
};
export const addB2cOrderFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const resetC2bOrderFields = (state = INITIAL_STATE) => {
  let c2bOrder = {};
  return { ...state, c2bOrder };
};

//Fetch seller inspection details
export const fetchSellerInspectionDetailInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};
export const fetchSellerInspectionDetailSuccess = (
  state = INITIAL_STATE,
  action
) => {
  const { data: sellerInspectionDetail } = action;
  return {
    ...state,
    sellerInspectionDetail,
    isLoading: false
  };
};
export const fetchSellerInspectionDetailFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

//define handlers
export const HANDLERS = {
  [Types.ACTION_FETCH_ORDER_DETAIL_INIT]: fetchOrderDetailInit,
  [Types.ACTION_FETCH_ORDER_DETAIL_SUCCESS]: fetchOrderDetailSuccess,
  [Types.ACTION_FETCH_ORDER_DETAIL_FAIL]: fetchOrderDetailFail,
  [Types.ACTION_UPDATE_ORDER_STATE_INIT]: updateOrderStateInit,
  [Types.ACTION_UPDATE_ORDER_STATE_SUCCESS]: updateOrderStateSuccess,
  [Types.ACTION_UPDATE_ORDER_STATE_FAIL]: updateOrderStateFail,
  [Types.ACTION_UPDATE_ORDER_INIT]: updateOrderInit,
  [Types.ACTION_UPDATE_ORDER_SUCCESS]: updateOrderSuccess,
  [Types.ACTION_UPDATE_ORDER_FAIL]: updateOrderFail,
  [Types.ACTION_FETCH_ORDER_INSPECTION_DETAIL_INIT]: fetchOrderInspectionDetail,
  [Types.ACTION_FETCH_ORDER_INSPECTION_DETAIL_SUCCESS]: fetchOrderInspectionDetailSuccess,
  [Types.ACTION_FETCH_ORDER_INSPECTION_DETAIL_FAIL]: fetchOrderInspectionDetailFail,
  [Types.ACTION_FETCH_C2B_ORDER_DETAIL_INIT]: fetchC2bOrderDetailInit,
  [Types.ACTION_FETCH_C2B_ORDER_DETAIL_SUCCESS]: fetchC2bOrderDetailSuccess,
  [Types.ACTION_FETCH_C2B_ORDER_DETAIL_FAIL]: fetchC2bOrderDetailFail,
  [Types.ACTION_ADD_B2C_ORDER_INIT]: addB2cOrderInit,
  [Types.ACTION_ADD_B2C_ORDER_SUCCESS]: addB2cOrderSuccess,
  [Types.ACTION_ADD_B2C_ORDER_FAIL]: addB2cOrderFail,
  [Types.ACTION_RESER_C2B_ORDER_FIELDS]: resetC2bOrderFields,
  [Types.ACTION_FETCH_SELLER_INSPECTION_DETAIL_INIT]: fetchSellerInspectionDetailInit,
  [Types.ACTION_FETCH_SELLER_INSPECTION_DETAIL_SUCCESS]: fetchSellerInspectionDetailSuccess,
  [Types.ACTION_FETCH_SELLER_INSPECTION_DETAIL_FAIL]: fetchSellerInspectionDetailFail
};

export default createReducer(INITIAL_STATE, HANDLERS);
