import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { StoreActions, InventoryActions, OrderActions } from "../../actions";
import { getStockOutReasons, getInventoryStatus } from "../../service/eav";
import { If, Else, Then, Switch, Case, Condition } from "react-if";
import { inventoryStatusConstants } from "../../constants/constants";
import { stockOutReasonsConstants } from "../../constants/constants";
import _ from "lodash";

class Inventory extends Component {
  state = {
    stores: [],
    appointmentId: "",
    inventoryStatus: "",
    nextInventoryStatus: "",
    stockOutReason: "",
    stockOutStoreId: "",
    stockInStoreId: "",
    inventoryLogData: [],
    parsedInventoryLogData: []
  };

  //handle input changes
  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    let data = {
      appointmentId: this.state.appointmentId,
      inventoryStatus: this.state.nextInventoryStatus,
      stockInStoreId: this.state.stockInStoreId
    };
    if (this.state.inventoryStatus == inventoryStatusConstants.STOCK_IN) {
      data = {
        ...data,
        stockOutReason: this.state.stockOutReason
      };
      if (this.state.stockOutStoreId) {
        data = {
          ...data,
          stockOutStoreId: this.state.stockOutStoreId
        };
      }
    }
    this.props.updateInventory(data).then(() => {
      this.props.fetchOrderDetail(data.appointmentId);
      this.props.getInventoryLog(this.state.appointmentId);
    });
  };

  componentWillReceiveProps({ detail, inventoryLogDetail }) {
    if (detail) {
      this.setState(detail);
      this.setState({
        stockInStoreId: detail.storeId ? detail.storeId : ""
      });
    }
    if (inventoryLogDetail) {
      this.setState({ inventoryLogData: inventoryLogDetail });
    }
  }

  async componentDidMount() {
    let storesData = await this.props.getStores({
      data: "all",
      status: "1"
    });
    let stores = storesData.response ? storesData.response : [];
    let storeData = [];
    stores.forEach(row => {
      storeData[row.id] = {
        storeName: row.storeName
      };
    });
    this.setState({ stores: stores, storeData: storeData });
  }

  getStockOutReasonsHtml = () => {
    let stockOutReasons = getStockOutReasons();
    let stockOutReasonsList = "";
    if (stockOutReasons) {
      stockOutReasonsList = Object.keys(stockOutReasons).map(function(
        value,
        index
      ) {
        return (
          <option key={value} value={value}>
            {stockOutReasons[value]}
          </option>
        );
      });
    }
    return stockOutReasonsList;
  };

  getInventoryLogHtml = () => {
    let inventoryLogData = !_.isEmpty(this.state.inventoryLogData)
      ? this.state.inventoryLogData
      : [];
    let defaultInventoryStatus = getInventoryStatus();
    let parsedInventoryLogData = [];
    let storeData = this.state.storeData;

    inventoryLogData.forEach((row, key) => {
      let stockInstore = row.stockInStoreId
        ? storeData[row.stockInStoreId]
          ? storeData[row.stockInStoreId].storeName
          : ""
        : "";
      let stockOutstore = row.stockOutStoreId
        ? storeData[row.stockOutStoreId]
          ? storeData[row.stockOutStoreId].storeName
          : ""
        : "";
      parsedInventoryLogData[key] = {
        inventoryStatus: defaultInventoryStatus[row.inventoryStatus],
        stockInStore: stockInstore,
        stockOutstore: stockOutstore,
        createdAt: row.createdAt,
        updatedAt: row.updatedAt
      };
    });

    let inventoryLogHtml = "";
    inventoryLogHtml = parsedInventoryLogData.map(row => {
      return (
        <tr>
          <td>{row.inventoryStatus ? row.inventoryStatus : "N/A"}</td>
          <td>{row.stockInStore ? row.stockInStore : "N/A"}</td>
          <td>{row.stockOutstore ? row.stockOutstore : "N/A"}</td>
          <td>{row.updatedAt ? row.updatedAt : "N/A"}</td>
        </tr>
      );
    });

    return inventoryLogHtml;
  };

  render() {
    let stores = this.state.stores;
    let storesList = "";
    let stockOutReasonsList = this.getStockOutReasonsHtml();
    if (stores && stores.length > 0) {
      storesList = stores.map(store => {
        return (
          <Fragment>
            <option key={store.id} value={store.id}>
              {store.storeName !== "" ? store.storeName : "N/A"}
            </option>
          </Fragment>
        );
      });
    }
    let inventoryLogHtml = this.getInventoryLogHtml();

    return (
      <Fragment>
        <div className="row">
          <form
            name="inventory"
            method="POST"
            onSubmit={e => this.handleSubmit(e)}
          >
            <div className="form-group col-md-12">
              <div className="form-group col-md-4">
                <label>Appointment Id</label>
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={
                    this.state.appointmentId ? this.state.appointmentId : ""
                  }
                />
              </div>

              <div className="form-group col-md-4">
                <label>Current Inventory Status</label>
                <input
                  type="text"
                  name="inventoryStatus"
                  className="form-control"
                  readOnly
                  onChange={e => this.handleInputChange(e)}
                  value={
                    this.state.inventoryStatus ? this.state.inventoryStatus : ""
                  }
                />
              </div>

              <div className="form-group col-md-4">
                <label>Next Inventory Status</label>
                <input
                  type="text"
                  name="nextInventoryStatus"
                  className="form-control"
                  readOnly
                  onChange={e => this.handleInputChange(e)}
                  value={
                    this.state.nextInventoryStatus
                      ? this.state.nextInventoryStatus
                      : ""
                  }
                />
              </div>

              <div className="form-group col-md-4">
                <label>Stock In Store</label>
                <select
                  className="form-control"
                  name="stockInStoreId"
                  onChange={e => this.handleInputChange(e)}
                  value={
                    this.state.stockInStoreId ? this.state.stockInStoreId : ""
                  }
                  required
                >
                  <option value="" key="">
                    --Select--
                  </option>
                  {storesList}
                </select>
              </div>

              <If
                condition={
                  this.state.inventoryStatus ===
                  inventoryStatusConstants.STOCK_IN
                }
              >
                <Then>
                  <div className="form-group col-md-4">
                    <label>Stock Out Reason</label>
                    <select
                      className="form-control"
                      name="stockOutReason"
                      onChange={e => this.handleInputChange(e)}
                      value={
                        this.state.stockOutReason
                          ? this.state.stockOutReason
                          : ""
                      }
                      required
                    >
                      <option value="" key="">
                        --Select--
                      </option>
                      {stockOutReasonsList}
                    </select>
                  </div>

                  <If
                    condition={
                      this.state.stockOutReason ==
                      stockOutReasonsConstants.C2C_STORE_MOVEMENT
                    }
                  >
                    <div className="form-group col-md-4">
                      <label>Stock Out Store</label>
                      <select
                        className="form-control"
                        name="stockOutStoreId"
                        onChange={e => this.handleInputChange(e)}
                        value={
                          this.state.stockOutStoreId
                            ? this.state.stockOutStoreId
                            : ""
                        }
                        required
                      >
                        <option value="" key="">
                          --Select--
                        </option>
                        {storesList}
                      </select>
                    </div>
                  </If>
                </Then>
              </If>
            </div>

            <If condition={this.state.inventoryStatus}>
              <Then>
                <div className="form-group col-md-8">
                  <div className="form-group col-md-5">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </Then>
              <Else>
                <div className="form-group col-md-8">
                  Car has not been stocked out from C2B.
                </div>
              </Else>
            </If>
          </form>
        </div>

        <If condition={this.state.inventoryLogData}>
          <Then>
            <div className="row">
              <h3>Inventory Log</h3>
              <div className="col-md-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Inventory Status</th>
                      <th scope="col">Stock In Store</th>
                      <th scope="col">Stock Out Store</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody>{inventoryLogHtml}</tbody>
                </table>
              </div>
            </div>
          </Then>
        </If>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  order: {
    order: { detail }
  },
  inventory: { inventoryLogDetail }
}) => {
  return {
    detail,
    inventoryLogDetail
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getStores: data => StoreActions.fetchAllStores(data),
      updateInventory: data => InventoryActions.updateInventory(data),
      getInventoryLog: appointmentId =>
        InventoryActions.getInventoryLog(appointmentId),
      fetchOrderDetail: appointmentId =>
        OrderActions.fetchOrderDetail(appointmentId)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
