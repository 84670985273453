import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import { rootReducer } from "./reducers";

const DEBUG = process.env.NODE_ENV === "development";

const configureStore = (initialState = {}) => {
  const composeEnhancers =
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    DEBUG
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  const enhancer = composeEnhancers(applyMiddleware(reduxThunk));
  return createStore(rootReducer, initialState, enhancer);
};

const store = configureStore({});
export default store;
