import Types from "../constants/actionTypes";

const toggleSidebar = () => ({
  type: Types.ACTION_TOGGLE_SIDEBAR
});

const toggleProfile = () => ({
  type: Types.ACTION_TOGGLE_PROFILE
});

export { toggleSidebar, toggleProfile };
