import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import MainGrid from "../mainGrid/mainGrid";
import Notfound from "./notfound";
import Dashboard from "../home/dashboard";
import Stock from "../stock/stock";
import StoreGrid from "../store/storeGrid";
import DealerGrid from "../dealers/dealerGrid";
import Store from "../store/store";
import Dealer from "../dealers/dealer";
import AgentGrid from "../agent/agentGrid";
import Agent from "../agent/agent";
import AddB2c from "../stock/addB2c";

class AppRouter extends Component {
  state = {
    uniqueKey: Date.now()
  };

  //Set unique key only when component receive some props
  componentWillReceiveProps() {
    this.setState({ uniqueKey: Date.now() });
  }

  //render
  render() {
    return (
      <section className="content flex">
        <div className="box box-success">
          <section className="content full-height">
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route
                exact
                path="/orders"
                render={props => (
                  <MainGrid key={this.state.uniqueKey} {...props} />
                )}
              />
              <Route
                path="/order/stock/:id"
                render={props => <Stock {...props} />}
              />
              <Route
                exact
                path="/stores"
                render={props => (
                  <StoreGrid key={this.state.uniqueKey} {...props} />
                )}
              />
              <Route
                path="/store/add"
                render={props => <Store {...props}></Store>}
              ></Route>
              <Route
                exact
                path="/dealers"
                render={props => (
                  <DealerGrid key={this.state.uniqueKey} {...props} />
                )}
              />
              <Route
                path="/dealer/add"
                render={props => <Dealer {...props}></Dealer>}
              ></Route>
              <Route
                path="/dealer/:id"
                render={props => <Dealer {...props}></Dealer>}
              ></Route>
              <Route
                exact
                path="/agents"
                render={props => (
                  <AgentGrid key={this.state.uniqueKey} {...props} />
                )}
              />
              <Route
                path="/agents/add"
                render={props => <Agent {...props}></Agent>}
              ></Route>
              <Route
                path="/agents/:id"
                render={props => <Agent {...props}></Agent>}
                path="/orders/add"
                render={props => <AddB2c {...props}></AddB2c>}
              ></Route>
              <Route path="/store/:id" render={props => <Store {...props} />} />
              <Route component={Notfound} />
            </Switch>
          </section>
        </div>
      </section>
    );
  }
}

export default AppRouter;
