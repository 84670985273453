import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { storeApi } from "./../../service/api";
import * as gridHelper from "./../../helper/mainGridHelper";
import LinkRenderer from "../common/grid/linkRenderer";
import LabelRenderer from "../common/grid/labelRenderer";
import DropDwonFilter from "../common/grid/dropDwonFilter";
import { Link } from "react-router-dom";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Paginator from "../common/grid/paginator";

class StoreGrid extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      page: 0,
      totalPages: 0,
      recordsOnPage: 0,
      totalRecords: 0,
      filterApplied: false,
      defaultColDef: {},
      columnDefs: [
        {
          headerName: "Store ID",
          field: "id",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            caseSensitive: true,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          },
          cellRenderer: "LinkRenderer",
          floatingFilterComponentParams: { suppressFilterButton: true }
        },
        {
          headerName: "Store Name",
          field: "storeName",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            caseSensitive: false,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          },
          cellRenderer: "LabelRenderer",
          floatingFilterComponentParams: { suppressFilterButton: true }
        },
        {
          headerName: "Phone Number",
          field: "storePhoneNumber",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            caseSensitive: false,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          },
          cellRenderer: "LabelRenderer",
          floatingFilterComponentParams: { suppressFilterButton: true }
        },
        {
          headerName: "Status",
          field: "status",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            caseSensitive: false,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          },
          floatingFilterComponent: "DropDwonFilter",
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            name: "status",
            options: [
              { key: "1", value: "Active" },
              { key: "0", value: "Inactive" }
            ]
          },
          cellRenderer: "LabelRenderer",
          cellRendererParams: {
            options: [
              { key: "1", value: "Active" },
              { key: "0", value: "Inactive" }
            ]
          },
          suppressMenu: true
        }
      ],
      context: { componentParent: this },
      frameworkComponents: {
        LinkRenderer: LinkRenderer,
        LabelRenderer: LabelRenderer,
        DropDwonFilter: DropDwonFilter
      }
    };
  }

  render() {
    return (
      <React-Fragement>
        <div className="row">
          <div className="col-md-1 pull-right">
            <Link to="/store/add">
              <button className="btn btn-success" type="button">
                Add
              </button>
            </Link>
          </div>
          <br />
        </div>
        <div className="row ag-theme-balham ag-grid-outer-wrapper">
          <AgGridReact
            columnDefs={this.state.columnDefs}
            rowData={this.state.rowData}
            defaultColDef={this.state.defaultColDef}
            context={this.state.context}
            paginationPageSize={10}
            floatingFilter
            onFilterChanged={this.handleFilterChanged}
            frameworkComponents={this.state.frameworkComponents}
            onGridReady={this.onGridReady}
            enableCellTextSelection={true}
          />
          <Paginator
            page={this.state.page}
            totalPages={this.state.totalPages}
            totalRecords={this.state.totalRecords}
            recordsOnPage={this.state.recordsOnPage}
            onClickPagination={this.navigatePagination}
          />
        </div>
      </React-Fragement>
    );
  }

  //Load stores on component mount
  async componentDidMount() {
    this._isMounted = true;
    const {
      response: stores,
      page,
      totalPages,
      totalRecords
    } = await storeApi.getStores();
    if (this._isMounted) {
      this.setState({
        rowData: stores,
        page: page,
        totalPages: totalPages,
        totalRecords: totalRecords,
        recordsOnPage: stores ? stores.length : 0
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //Clear all filters
  clearFilters = () => {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  };

  //Redirect to stock detail page
  redirectToDetailsPage(id) {
    this.props.history.push("/store/" + id);
  }

  //Get name of unique identifier for each row
  getUniqueIdName() {
    return "id";
  }

  //Set grid and columns
  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  //Handle grid filters
  handleFilterChanged = () => {
    this.resetGridWithNewData();
  };

  //Navigate pagination
  navigatePagination = async pageNo => {
    const model = { ...this.gridApi.getFilterModel() };
    const query = gridHelper.createFilterQuery(model);
    const {
      response: stores,
      page,
      totalPages,
      totalRecords
    } = await storeApi.getStores(query, pageNo);
    this.setState({
      rowData: stores,
      page: page,
      totalPages: totalPages,
      totalRecords: totalRecords,
      recordsOnPage: stores ? stores.length : 0
    });
    if (query) {
      this.gridApi.setFilterModel(model);
    }
    this.setState({ filterApplied: true });
  };

  //Reset grid with newly fetched data
  resetGridWithNewData = async pageNo => {
    if (!this.state.filterApplied) {
      const model = { ...this.gridApi.getFilterModel() };
      const query = gridHelper.createFilterQuery(model);
      const {
        response: stores,
        page,
        totalPages,
        totalRecords
      } = await storeApi.getStores(query, pageNo);
      this.setState({
        rowData: stores,
        page: page,
        totalPages: totalPages,
        totalRecords: totalRecords,
        recordsOnPage: stores ? stores.length : 0
      });
      if (query) {
        this.gridApi.setFilterModel(model);
      }
      this.setState({ filterApplied: true });
    } else {
      this.setState({ filterApplied: false });
    }
  };
}

export default StoreGrid;
