import * as httpService from "./httpService";
import { config } from "./../config/local";
import { toast } from "react-toastify";
import { httpConstants } from "../constants/httpConstants";

const HTTP_STATUS_OK = 200;

//Get c2c orders from API
export async function getOrders(params = {}, page = 0, size = 10) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.orders.path;
  const url = host.concat(path);
  params.page = page;
  params.size = size;
  const auth = false;
  const response = await httpService.get(url, params, auth);
  if (response && response.status === HTTP_STATUS_OK) {
    return response.data ? response.data : [];
  } else {
    return [];
  }
}

//Get c2b order details from API
export async function getC2bOrderDetails(appointmentId) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.c2bOrders.path;
  const url = host
    .concat(path)
    .concat("/")
    .concat(appointmentId);
  const response = await httpService.get(url, {});
  if (response && response.status === HTTP_STATUS_OK) {
    return response.data;
  } else {
    return [];
  }
}

//Get c2c order details from API
export async function getOrderDetails(appointmentId) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.orders.path;
  const url = host
    .concat(path)
    .concat("/")
    .concat(appointmentId);
  const response = await httpService.get(url, {});
  if (response && response.status === HTTP_STATUS_OK) {
    return response.data;
  } else {
    return [];
  }
}

//Get c2c orders inventory details from API
export async function getOrderInventoryDetails(params = {}) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.inventoryDetails.path;
  const url = host.concat(path);
  const response = await httpService.get(url, params);
  if (response && response.status === HTTP_STATUS_OK) {
    return response.data ? response.data.details : [];
  } else {
    return [];
  }
}

//Get c2c orders inventory details from API
export async function getDepositionsList(orderState) {
  if (!orderState) {
    return [];
  }
  const host = config.api.c2c.host;
  const path = config.api.c2c.despositions.path;
  const url = host
    .concat(path)
    .concat("/")
    .concat(orderState)
    .concat("/despositions");
  const response = await httpService.get(url, {});
  if (response && response.status === HTTP_STATUS_OK) {
    return response.data;
  } else {
    return [];
  }
}

//Get c2c orders inventory details from API
export async function getSellerInfo(appointmentId) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.inventory.path;
  const url = host
    .concat(path)
    .concat("/")
    .concat(appointmentId);
  const response = await httpService.get(url, {});
  if (response && response.status === HTTP_STATUS_OK) {
    return response.data;
  } else {
    return [];
  }
}

//Get c2c order details from API
export async function getOrderPageDetails(appointmentId) {
  const order = await getOrderDetails(appointmentId);
  const orderState =
    order && order.detail && order.detail.orderState
      ? order.detail.orderState
      : null;
  const depositionsList = await getDepositionsList(orderState);
  return {
    order: order,
    despositions: depositionsList
  };
}

//Update order state for given appointmentId
export async function updateOrderState(data) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.updateOrderState.path;
  const url = host
    .concat(path)
    .concat("/")
    .concat(data.appointmentId)
    .concat("/state/")
    .concat(data.orderState);
  let post = {
    remarks: data.remarks
  };

  if (data.soldPrice) {
    post.soldPrice = data.soldPrice;
  }
  const response = await httpService.put(url, post);
  if (response && response.status === HTTP_STATUS_OK) {
    toast.success("Order state has been updated successfully");
    return response.data;
  } else {
    return [];
  }
}

//Update order deails for given appointmentId
export async function updateOrder(data) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.updateOrder.path;
  const url = host.concat(path);
  const response = await httpService.put(url, data);
  if (response && response.status === HTTP_STATUS_OK) {
    toast.success("Order has been updated successfully");
    return response.data;
  } else {
    return [];
  }
}

//Get c2c order inspection details from API
export async function getOrderInspectionDetails(appointmentId) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.inspection.path;
  const url = host
    .concat(path)
    .concat("/")
    .concat(appointmentId);
  const response = await httpService.get(url);
  if (response && response.status === HTTP_STATUS_OK) {
    return response.data ? response.data.detail : [];
  } else {
    return [];
  }
}

//Update car onboard data
export async function updateOnboardInventory(data) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.onboardInventory.path;
  const url = host.concat(path);
  const response = await httpService.post(url, data);
  if (response && response.status === HTTP_STATUS_OK) {
    toast.success("Data updated successfully");
  }
}
//add b2c order
export async function addB2cOrder(data) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.addC2bOrder.path;
  const url = host.concat(path);
  const response = await httpService.post(url, data);
  if (response && response.status === HTTP_STATUS_OK) {
    toast.success("Order has been created successfully");
    return response.data;
  } else {
    return [];
  }
}

//Get seller inspection details by apptId
export async function getSellerInspectionDetailByAppointmentId(appointmentId) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.sellerInspectionDetail.path;
  const url = host
    .concat(path)
    .concat("/")
    .concat(appointmentId);
  const response = await httpService.get(url);
  if (response && response.status === httpConstants.HTTP_STATUS_OK) {
    return response.data ? response.data : [];
  } else {
    return [];
  }
}
