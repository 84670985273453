import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { HomeActions, UserActions } from "./../../actions";

class Header extends Component {
  //Render
  render() {
    return (
      <header className="main-header">
        <a href="/" className="logo no-deco no-padding">
          <span className="logo-mini">
            <b>C</b>24
          </span>
          <span className="logo-lg">
            <b>C2C</b>&nbsp;Panel
          </span>
        </a>
        <nav className="navbar navbar-static-top" role="navigation">
          <a
            href="# "
            className="sidebar-toggle"
            data-toggle="offcanvas"
            role="button"
            onClick={() => this.props.toggleSidebar()}
          >
            <span className="sr-only">Toggle navigation</span>
          </a>
          <div
            className={
              this.props.isLoggedIn
                ? "navbar-custom-menu"
                : "navbar-custom-menu hide"
            }
            onClick={() => this.props.toggleProfile()}
          >
            <ul className="nav navbar-nav">
              <li
                className={
                  this.props.profile
                    ? "dropdown user user-menu open"
                    : "dropdown user user-menu"
                }
              >
                <a
                  href="# "
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  aria-expanded={this.props.profile}
                >
                  <img src="/logo.png" className="user-image" alt="User" />
                  <span className="hidden-xs">{this.props.email}</span>
                </a>
                <ul className="dropdown-menu">
                  <li className="user-header">
                    <img src="/logo.png" className="img-circle" alt="User" />
                    <p>C2C User</p>
                  </li>
                  <li className="user-footer">
                    <div className="pull-right">
                      <a
                        href="# "
                        className="btn btn-default btn-flat"
                        onClick={() => this.props.logout()}
                      >
                        Sign out
                      </a>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

Header.propTypes = {};

//states coming from store defined in rootReducer
const mapStateToProps = ({
  home: { sidebar, profile },
  user: { isLoggedIn, email }
}) => {
  return {
    sidebar,
    profile,
    isLoggedIn,
    email
  };
};

//actions being dispatached to invoke reducers
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleSidebar: HomeActions.toggleSidebar,
      toggleProfile: HomeActions.toggleProfile,
      logout: UserActions.logout
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
