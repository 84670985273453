import { createReducer } from "reduxsauce";
import Types from "../constants/actionTypes";

export const INITIAL_STATE = {
  isLoggedIn: true,
  email: null,
  user: null
};

export const updateLoggedInStatus = (state = INITIAL_STATE, { isLoggedIn }) => {
  return { ...state, isLoggedIn };
};

export const updateLoggedInUser = (state = INITIAL_STATE, { email }) => {
  return { ...state, email };
};

export const logout = (state = INITIAL_STATE) => {
  return {};
};

//Get user details
export const setUserDetails = (state = INITIAL_STATE, { user }) => {
  return { ...state, email: user ? user.email : null };
};

//Handlers
export const HANDLERS = {
  [Types.ACTION_UPDATE_LOGGEDIN_STATUS]: updateLoggedInStatus,
  [Types.ACTION_UPDATE_LOGGEDIN_USER]: updateLoggedInUser,
  [Types.ACTION_LOGOUT]: logout,
  [Types.ACTION_SET_USER]: setUserDetails
};

export default createReducer(INITIAL_STATE, HANDLERS);
