import * as httpService from "./httpService";
import { httpConstants } from "../constants/httpConstants";
import { config } from "../config/local";
import { toast } from "react-toastify";
import { async } from "q";

//get agents list
export const getAgents = async (params = {}, page = 0, size = 10) => {
  const host = config.api.c2c.host;
  const path = config.api.c2c.agents.path;
  const url = host.concat(path);
  params.page = page;
  params.size = size;
  const response = await httpService.get(url, params);
  if (response && response.status === httpConstants.HTTP_STATUS_OK) {
    return response.data ? response.data : [];
  } else {
    return [];
  }
};

//get agent by id
export const getAgentById = async id => {
  const host = config.api.c2c.host;
  const path = config.api.c2c.agents.path;
  const url = host
    .concat(path)
    .concat("/")
    .concat(id);

  const response = await httpService.get(url);
  if (response && response.status === httpConstants.HTTP_STATUS_OK) {
    return response.data ? response.data : [];
  } else {
    return [];
  }
};

//add agent
export const addAgent = async (data, params) => {
  const host = config.api.c2c.host;
  const path = config.api.c2c.agents.path;
  const url = host.concat(path);
  const response = await httpService.post(url, data, params);
  if (response && response.status === httpConstants.HTTP_STATUS_OK) {
    toast.success("Agent has been updated successfully");
    return response.data ? response.data : [];
  } else {
    return [];
  }
};

//update agent
export const updateAgentStatus = async (data, params) => {
  const host = config.api.c2c.host;
  const path = config.api.c2c.agentStatusUpdate.path;
  const url = host.concat(path);
  const response = await httpService.post(url, data, params);
  if (response && response.status === httpConstants.HTTP_STATUS_OK) {
    toast.success("Status has been updated successfully");
    return response.data ? response.data : [];
  } else {
    return [];
  }
};
