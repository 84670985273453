import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { StoreActions, StateActions, CityActions } from "../../actions";
import { async } from "q";

class Store extends Component {
  state = {
    id: "",
    cityId: "",
    stateId: "",
    address: "",
    status: "",
    mapImageLinkDesktop: "",
    mapImageLinkMobile: "",
    shareLink: "",
    storeName: "",
    storePhoneNumber: "",
    isDefault: false,
    cities: [],
    states: []
  };

  //Load car details and seller info from API
  async componentDidMount() {
    const storeId = this.props.match.params.id;
    if (storeId) {
      await this.props.fetchStoreDetail(storeId);
    }
    if (this.state.states.length == 0) {
      const states = await this.props.fetchStates();
      this.setState({ states: states });
      let cities = await this.props.fetchCities({
        stateId: this.state.stateId
      });
      if (cities && cities.length > 0) {
        this.setState({ cities: cities });
      }
    }
  }

  //handle input changes
  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value
      },
      async function() {
        if (name == "stateId") {
          //TODO:: Make this common as same is used in didMount
          let cities = await this.props.fetchCities({
            stateId: this.state.stateId
          });
          if (cities && cities.length > 0) {
            this.setState({ cities: cities });
          }
        }
      }
    );
  };

  //handle submit
  handleSubmit = e => {
    e.preventDefault();
    const data = {
      id: this.state.id,
      storeName: this.state.storeName,
      cityId: this.state.cityId,
      stateId: this.state.stateId,
      address: this.state.address,
      status: this.state.status ? 1 : 0,
      mapImageLinkDesktop: this.state.mapImageLinkDesktop,
      mapImageLinkMobile: this.state.mapImageLinkMobile,
      shareLink: this.state.shareLink,
      storeName: this.state.storeName,
      storePhoneNumber: this.state.storePhoneNumber,
      isDefault: this.state.isDefault
    };
    this.props.addStore(data);
  };

  //component will receive props
  componentWillReceiveProps({ detail }) {
    if (detail) {
      if (Object.keys(detail).length > 0) {
        this.setState(detail);
      }
    }
  }

  //render
  render() {
    const states = this.state.states;
    let statesList = "";
    if (states && states.length > 0) {
      statesList = states.map(state => {
        return (
          <option key={state.stateId} value={state.stateId}>
            {state.stateName}
          </option>
        );
      });
    }

    const cities = this.state.cities;
    let citiesList = "";
    if (cities && cities.length > 0) {
      citiesList = cities.map(city => {
        return (
          <option key={city.cityId} value={city.cityId}>
            {city.cityName}
          </option>
        );
      });
    }

    return (
      <form name="store" method="POST" onSubmit={e => this.handleSubmit(e)}>
        <div className="form-group col-md-12">
          <div className="form-group col-md-5">
            <input type="hidden" name="id" value={this.state.id} />
            <label>Name</label>
            <input
              className="form-control"
              type="text"
              name="storeName"
              value={this.state.storeName}
              onChange={e => this.handleInputChange(e)}
              required
            />
          </div>

          <div className="form-group col-md-5">
            <label>Phone Number</label>
            <input
              className="form-control"
              type="text"
              name="storePhoneNumber"
              value={this.state.storePhoneNumber}
              onChange={e => this.handleInputChange(e)}
              required
              maxLength={10}
            />
          </div>

          <div className="form-group col-md-5">
            <label>State</label>
            <select
              className="form-control"
              name="stateId"
              id="stateId"
              onChange={e => this.handleInputChange(e)}
              required
              value={this.state.stateId}
            >
              <option value="">--Select State--</option>
              {statesList}
            </select>
          </div>

          <div className="form-group col-md-5">
            <label>City</label>
            <select
              className="form-control"
              name="cityId"
              id="cityId"
              onChange={e => this.handleInputChange(e)}
              required
              value={this.state.cityId}
            >
              <option value="">--Select City--</option>
              {citiesList}
            </select>
          </div>

          <div className="form-group col-md-5">
            <label>Share Link</label>
            <textarea
              className="form-control"
              type="text"
              cols="50"
              rows="3"
              name="shareLink"
              value={this.state.shareLink}
              onChange={e => this.handleInputChange(e)}
              required
            />
          </div>

          <div className="form-group col-md-5">
            <label>Map Image (Desktop)</label>
            <textarea
              className="form-control"
              type="text"
              cols="50"
              rows="3"
              name="mapImageLinkDesktop"
              value={this.state.mapImageLinkDesktop}
              onChange={e => this.handleInputChange(e)}
            />
          </div>

          <div className="form-group col-md-5">
            <label>Map Image (Mobile)</label>
            <textarea
              className="form-control"
              type="text"
              cols="50"
              rows="3"
              name="mapImageLinkMobile"
              value={this.state.mapImageLinkMobile}
              onChange={e => this.handleInputChange(e)}
              required
            />
          </div>

          <div className="form-group col-md-5">
            <label>Address</label>
            <textarea
              className="form-control"
              type="text"
              cols="50"
              rows="3"
              name="address"
              value={this.state.address}
              onChange={e => this.handleInputChange(e)}
              required
            />
          </div>

          <div className="form-group col-md-3">
            <label>Active</label>
            <input
              className="checkbox"
              type="checkbox"
              name="status"
              checked={this.state.status}
              onChange={e => this.handleInputChange(e)}
            />
          </div>

          <div className="form-group col-md-3">
            <label>Default</label>
            <input
              className="checkbox"
              type="checkbox"
              name="isDefault"
              checked={this.state.isDefault}
              onChange={e => this.handleInputChange(e)}
            />
          </div>
        </div>

        <div className="form-group col-md-8">
          <div className="rows">&nbsp;</div>
          <div className="form-group col-md-5">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </form>
    );
  }
}

Store.propTypes = {};
const mapStateToProps = ({ store: { detail } }) => {
  return {
    detail
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchStoreDetail: storeId => StoreActions.fetchStoreDetail(storeId),
      addStore: data => StoreActions.addStore(data),
      fetchStates: () => StateActions.fetchStates(),
      fetchCities: params => CityActions.fetchCities(params)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Store);
