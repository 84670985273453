import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { StateActions, CityActions, AgentActions } from "../../actions";
import { bindActionCreators } from "redux";
import { getLegalStatus } from "../../service/eav";
import { If, Then } from "react-if";
import { constants, agentStatusConstants } from "../../constants/constants";
import { Redirect } from "react-router-dom";
import { config } from "../../config/local";

class BasicDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agentId: "",
      agentCode: "",
      agentName: "",
      agentStateId: "",
      phone: "",
      email: "",
      legalStatus: "",
      address: "",
      agentCityId: "",
      agentCity: "",
      postalCode: "",
      agentStates: [],
      agentCities: [],
      operationStateId: "",
      operationCityId: "",
      operationStates: [],
      operationCities: [],
      agentVerificationCode: "",
      agentStatus: "",
      formAction: constants.FORM_ACTION_SAVE
    };
  }

  async componentDidMount() {
    if (this.state.agentStates.length == 0) {
      const states = await this.props.fetchStates();
      this.setState({ agentStates: states, operationStates: states });
    }
  }

  componentWillReceiveProps({ agentBasicDetails }) {
    if (agentBasicDetails) {
      this.setState(agentBasicDetails, function() {
        if (this.state.agentStateId) {
          this.setAgentCities();
        }
        if (this.state.operationStateId) {
          this.setOperationCities();
        }
        if (this.state.agentId) {
          this.setState({ formAction: constants.FORM_ACTION_UPDATE });
        }
      });
    }
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "agentCityId") {
      this.setState({
        agentCity: target.options[target.selectedIndex].text
      });
    }
    this.setState(
      {
        [name]: value
      },
      async function() {
        if (name === "agentStateId") {
          this.setAgentCities();
        }
        if (name === "operationStateId") {
          this.setOperationCities();
        }
      }
    );
  };

  handleBasicDetailSubmit = e => {
    e.preventDefault();
    const data = {
      agentBasicDetails: {
        agentId: this.state.agentId,
        agentName: this.state.agentName,
        agentStateId: this.state.agentStateId,
        agentCityId: this.state.agentCityId,
        phone: this.state.phone,
        email: this.state.email,
        legalStatus: this.state.legalStatus,
        address: this.state.address,
        postalCode: this.state.postalCode,
        operationStateId: this.state.operationStateId,
        operationCityId: this.state.operationCityId,
        agentVerificationCode: this.state.agentVerificationCode,
        agentStatus: this.state.agentStatus,
        agentCity: this.state.agentCity
      }
    };
    this.props.addAgent(data).then(() => {
      //redirect only when new agent is added
      if (this.state.formAction === constants.FORM_ACTION_SAVE) {
        this.props.history.push(config.api.c2c.agents.path);
      }
    });
  };

  getCitiesOptionsHtml = cities => {
    let citiesList = "";
    if (cities && cities.length > 0) {
      citiesList = cities.map(row => {
        return (
          <option key={row.cityId} value={row.cityId}>
            {row.cityName}
          </option>
        );
      });
    }
    return citiesList;
  };

  getStateOptionsHtml = states => {
    let statesList = "";
    if (states && states.length > 0) {
      statesList = states.map(state => {
        return (
          <option key={state.stateId} value={state.stateId}>
            {state.stateName}
          </option>
        );
      });
    }
    return statesList;
  };

  getLegalStatusOptionsHtml = () => {
    let legalStatus = getLegalStatus();
    let legalStatusList = "";
    if (legalStatus) {
      legalStatusList = Object.keys(legalStatus).map(function(value, index) {
        return (
          <option key={value} value={value}>
            {legalStatus[value]}
          </option>
        );
      });
    }
    return legalStatusList;
  };

  setAgentCities = async () => {
    let agentCities = await this.props.fetchCities({
      stateId: this.state.agentStateId
    });
    if (agentCities && agentCities.length > 0) {
      this.setState({ agentCities });
    }
  };

  setOperationCities = async () => {
    let operationCities = await this.props.fetchCities({
      stateId: this.state.operationStateId
    });
    if (operationCities && operationCities.length > 0) {
      this.setState({ operationCities });
    }
  };

  render() {
    let statesList = this.getStateOptionsHtml(this.state.agentStates);
    let citiesList = this.getCitiesOptionsHtml(this.state.agentCities);
    let operationStatesList = this.getStateOptionsHtml(
      this.state.operationStates
    );
    let operationCitiesList = this.getCitiesOptionsHtml(
      this.state.operationCities
    );
    let legalStatusList = this.getLegalStatusOptionsHtml();

    return (
      <form
        name="basicDetail"
        autoComplete="off"
        onSubmit={e => this.handleBasicDetailSubmit(e)}
      >
        <div className="row">
          <div className="form-group col-md-3">
            <label>Agent Name* </label>
            <input
              name="agentName"
              type="text"
              autoComplete="off"
              value={this.state.agentName}
              className="form-control test"
              onChange={e => this.handleInputChange(e)}
              required
            />
          </div>
          <div className="form-group col-md-3">
            <label>Phone Number*</label>
            <input
              name="phone"
              type="text"
              autoComplete="off"
              className="form-control"
              value={this.state.phone}
              onChange={e => this.handleInputChange(e)}
              maxLength={10}
              pattern="^[0-9]{10}$"
              required
              title="10 digits numeric"
            />
          </div>

          <div className="form-group col-md-3">
            <label>Email*</label>
            <input
              name="email"
              type="email"
              autoComplete="off"
              className="form-control"
              value={this.state.email}
              onChange={e => this.handleInputChange(e)}
              required
            />
          </div>
          <div className="form-group col-md-3">
            <label>Legal Status*</label>
            <select
              name="legalStatus"
              type="text"
              autoComplete="off"
              className="form-control"
              value={this.state.legalStatus}
              onChange={e => this.handleInputChange(e)}
              required
            >
              <option value="">--Select--</option>
              {legalStatusList}
            </select>
          </div>

          <div className="form-group col-md-3">
            <label>Operation State*</label>
            <select
              className="form-control"
              name="operationStateId"
              id="operationStateId"
              onChange={e => this.handleInputChange(e)}
              required
              value={this.state.operationStateId}
              required
            >
              <option value="">--Select State--</option>
              {operationStatesList}
            </select>
          </div>

          <div className="form-group col-md-3">
            <label>Operation City*</label>
            <select
              className="form-control"
              name="operationCityId"
              id="operationCityId"
              onChange={e => this.handleInputChange(e)}
              required
              value={this.state.operationCityId}
              required
            >
              <option value="">--Select City--</option>
              {operationCitiesList}
            </select>
          </div>

          <div className="form-group col-md-6">
            <label>Address*</label>
            <textarea
              name="address"
              type="text"
              autoComplete="off"
              className="form-control"
              value={this.state.address}
              onChange={e => this.handleInputChange(e)}
              required
            />
          </div>
          <div className="form-group col-md-3">
            <label>State*</label>
            <select
              className="form-control"
              name="agentStateId"
              id="agentStateId"
              onChange={e => this.handleInputChange(e)}
              required
              value={this.state.agentStateId}
            >
              <option value="">--Select State--</option>
              {statesList}
            </select>
          </div>

          <div className="form-group col-md-3">
            <label>City*</label>
            <select
              className="form-control"
              name="agentCityId"
              id="agentCityId"
              onChange={e => this.handleInputChange(e)}
              required
              value={this.state.agentCityId}
            >
              <option value="">--Select City--</option>
              {citiesList}
            </select>
          </div>
          <div className="form-group col-md-3">
            <label>Postal Code*</label>
            <input
              name="postalCode"
              type="text"
              autoComplete="off"
              className="form-control"
              value={this.state.postalCode}
              onChange={e => this.handleInputChange(e)}
              maxLength={6}
              required
            />
          </div>

          <If
            condition={
              this.state.agentStatus === agentStatusConstants.ASSIGNED_TO_VENDOR
            }
          >
            <Then>
              <div className="form-group col-md-3">
                <label>Agent Verification Code</label>
                <input
                  name=""
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  value={this.state.agentVerificationCode}
                  onChange={e => this.handleInputChange(e)}
                  required
                />
              </div>
            </Then>
          </If>

          <If
            condition={
              this.state.agentStatus !== agentStatusConstants.APPROVED &&
              this.state.agentStatus !== agentStatusConstants.REJECTED
            }
          >
            <Then>
              <div className="form-group col-md-8">
                <button type="submit" className="btn btn-primary">
                  {this.state.formAction}
                </button>
              </div>
            </Then>
          </If>
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({ agent: { detail } }) => {
  const { agentBasicDetails } = detail;
  return {
    agentBasicDetails
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addAgent: (data, params) => AgentActions.addAgent(data, params),
      fetchStates: () => StateActions.fetchStates(),
      fetchCities: params => CityActions.fetchCities(params)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BasicDetail));
