import { createReducer } from "reduxsauce";
import Types from "../constants/actionTypes";

export const INITIAL_STATE = {
  detail: {},
  isLoading: false
};

//Agent add actions starts here
export const addAgentInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const addAgentSuccess = (state = INITIAL_STATE, { data: detail }) => {
  return { ...state, detail, isLoading: false };
};

export const addAgentFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

//Agent fetch detail actions starts here
export const fetchAgentDetailInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const fetchAgentDetailSuccess = (
  state = INITIAL_STATE,
  { data: detail }
) => {
  return { ...state, detail, isLoading: false };
};

export const fetchAgentDetailFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

// Agent status update actions starts here
export const updateAgentStatusInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const updateAgentStatusSuccess = (
  state = INITIAL_STATE,
  { data: detail }
) => {
  return { ...state, detail, isLoading: false };
};

export const updateAgentStatusFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const HANDLERS = {
  [Types.ACTION_ADD_AGENT_INIT]: addAgentInit,
  [Types.ACTION_ADD_AGENT_SUCCESS]: addAgentSuccess,
  [Types.ACTION_ADD_AGENT_FAIL]: addAgentFail,
  [Types.ACTION_FETCH_AGENT_DETAIL_INIT]: fetchAgentDetailInit,
  [Types.ACTION_FETCH_AGENT_DETAIL_SUCCESS]: fetchAgentDetailSuccess,
  [Types.ACTION_FETCH_AGENT_DETAIL_FAIL]: fetchAgentDetailFail,
  [Types.ACTION_AGENT_STATUS_UPDATE_INIT]: updateAgentStatusInit,
  [Types.ACTION_AGENT_STATUS_UPDATE_SUCCESS]: updateAgentStatusSuccess,
  [Types.ACTION_AGENT_STATUS_UPDATE_FAIL]: updateAgentStatusFail
};

export default createReducer(INITIAL_STATE, HANDLERS);
