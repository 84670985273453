import { resettableReducer } from "reduxsauce";
import { combineReducers } from "redux";
import commonReducer from "./commonReducer";
import UserReducer from "./userReducer";
import HomeReducer from "./homeReducer";
import OrderReducer from "./orderReducer";
import StoreReducer from "./storeReducer";
import DealerReducer from "./dealerReducer";
import AgentReducer from "./agentReducer";
import UploadReducer from "./uploadReducer";
import InventoryReducer from "./inventoryReducer";

const resettable = resettableReducer("RESET");

const rootReducer = combineReducers({
  common: resettable(commonReducer),
  home: resettable(HomeReducer),
  user: resettable(UserReducer),
  order: resettable(OrderReducer),
  store: resettable(StoreReducer),
  dealer: resettable(DealerReducer),
  agent: resettable(AgentReducer),
  upload: resettable(UploadReducer),
  inventory: resettable(InventoryReducer)
});

export { rootReducer };
