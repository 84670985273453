import * as httpService from "./httpService";
import { httpConstants } from "../constants/httpConstants";
import { config } from "../config/local";
import { toast } from "react-toastify";

//upload file
export const uploadFile = async (path, params, fileObject) => {
  if (!path) {
    toast.error("Path cannot be empty!");
  }
  if (!fileObject) {
    toast.error("File cannot be empty!");
  }
  const host = config.api.c2c.host;
  const url = host.concat(path);
  const response = await httpService.fileUpload(url, params, fileObject);
  if (response && response.status === httpConstants.HTTP_STATUS_OK) {
    toast.success("File has been uploaded successfully");
    return response.data ? response.data : [];
  } else {
    return [];
  }
};
