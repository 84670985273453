import * as httpService from "./httpService";
import { config } from "./../config/local";
import { toast } from "react-toastify";

const HTTP_STATUS_OK = 200;

//Get c2c stores from API
export async function getStores(params = {}, page = 0, size = 10) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.stores.path;
  const url = host.concat(path);
  if (!params.data) {
    params.page = page;
    params.size = size;
  }
  const auth = false;
  const response = await httpService.get(url, params, auth);
  if (response && response.status === HTTP_STATUS_OK) {
    return response.data ? response.data : [];
  } else {
    return [];
  }
}

//Get c2c store details from API
export async function getStoreDetails(storeId) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.stores.path;
  const url = host
    .concat(path)
    .concat("/")
    .concat(storeId);
  const response = await httpService.get(url, {});
  if (response && response.status === HTTP_STATUS_OK) {
    return response.data;
  } else {
    return [];
  }
}

//add store
export async function addStore(data) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.stores.path;
  const url = host.concat(path);
  const response = await httpService.post(url, data);
  if (response && response.status === HTTP_STATUS_OK) {
    toast.success("Store has been updated successfully");
    return response.data;
  } else {
    return [];
  }
}
