import React, { Component } from "react";
import Seller from "./seller";
import CarDetails from "./carDetails";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { OrderActions, InventoryActions } from "./../../actions";
import Publish from "./publish";
import BasicDetailsForm from "./basicDetailsForm";
import Inspection from "./inspection";
import DealerDetail from "./dealerDetail";
import Inventory from "./inventory";
import { If } from "react-if";
import { dealerTypeConstants } from "../../constants/constants";
import { initialiseAgGridWithWebComponents } from "ag-grid-community";

class Stock extends Component {
  state = {
    selectedIndex: 0
  };

  //handle on click
  handleOnClick = index => {
    this.setState({ selectedIndex: index });
  };

  //Get order inspection details
  getOrderInspectionDetails = () => {
    const appointmentId = this.props.match.params.id;
    this.props.getOrderInspectionDetails(appointmentId);
  };

  //Get inventory log details
  getInventoryLogDetails = () => {
    const appointmentId = this.props.match.params.id;
    this.props.getInventoryLog(appointmentId);
  };

  //Render
  render() {
    const Tabs = () => {
      return (
        <ul className="nav nav-tabs">
          <li
            className={this.state.selectedIndex === 0 ? "active" : ""}
            onClick={() => this.handleOnClick(0)}
          >
            <a href="#car" data-toggle="tab" aria-expanded="true">
              <b>Car Information</b>
            </a>
          </li>
          <li
            className={this.state.selectedIndex === 1 ? "active" : ""}
            onClick={() => this.handleOnClick(1)}
          >
            <a href="#seller" data-toggle="tab" aria-expanded="false">
              <b>Seller Information</b>
            </a>
          </li>
          <li
            className={this.state.selectedIndex === 2 ? "active" : ""}
            onClick={() => this.handleOnClick(2)}
          >
            <a href="#update" data-toggle="tab" aria-expanded="false">
              <b>Update Details</b>
            </a>
          </li>
          <li
            className={this.state.selectedIndex === 3 ? "active" : ""}
            onClick={() => this.handleOnClick(3)}
          >
            <a href="#publish" data-toggle="tab" aria-expanded="false">
              <b>Publish/Archive</b>
            </a>
          </li>
          <li
            className={this.state.selectedIndex === 4 ? "active" : ""}
            onClick={() => {
              this.handleOnClick(4);
              this.getOrderInspectionDetails();
            }}
          >
            <a href="#inspection" data-toggle="tab" aria-expanded="false">
              <b>Inspection Details</b>
            </a>
          </li>
          <If
            condition={
              this.props.orderDetails &&
              this.props.orderDetails.isTopSelling == true
            }
          >
            <li
              className={this.state.selectedIndex === 5 ? "active" : ""}
              onClick={() => {
                this.handleOnClick(5);
              }}
            >
              <a href="#dealerDetail" data-toggle="tab" aria-expanded="false">
                <b>Dealer Details</b>
              </a>
            </li>
          </If>
          <If
            condition={
              this.props.orderDetails &&
              this.props.orderDetails.carSource ==
                dealerTypeConstants.DEALER_TYPE_GS_CAR
            }
          >
            <li
              className={this.state.selectedIndex === 6 ? "active" : ""}
              onClick={() => {
                this.handleOnClick(6);
                this.getInventoryLogDetails();
              }}
            >
              <a href="#inventory" data-toggle="tab" aria-expanded="false">
                <b>Inventory Management Details</b>
              </a>
            </li>
          </If>
        </ul>
      );
    };
    return (
      <React.Fragment>
        <div className="col-md-12">
          <div className="nav-tabs-custom no-box-shadow ">
            <Tabs />
            <div className="tab-content">
              <div
                className={
                  this.state.selectedIndex === 0
                    ? "tab-pane active"
                    : "tab-pane"
                }
              >
                <CarDetails />
              </div>
              <div
                className={
                  this.state.selectedIndex === 1
                    ? "tab-pane active"
                    : "tab-pane"
                }
              >
                <Seller />
              </div>
              <div
                className={
                  this.state.selectedIndex === 2
                    ? "tab-pane active"
                    : "tab-pane"
                }
              >
                <BasicDetailsForm />
              </div>
              <div
                className={
                  this.state.selectedIndex === 3
                    ? "tab-pane active"
                    : "tab-pane"
                }
              >
                <Publish />
              </div>
              <div
                className={
                  this.state.selectedIndex === 4
                    ? "tab-pane active"
                    : "tab-pane"
                }
              >
                <Inspection inspection={this.props.inspection} />
              </div>
              <If
                condition={
                  this.props.orderDetails &&
                  this.props.orderDetails.isTopSelling == true
                }
              >
                <div
                  className={
                    this.state.selectedIndex === 5
                      ? "tab-pane active"
                      : "tab-pane"
                  }
                >
                  <DealerDetail
                    dealerDetails={this.props.dealerDetails}
                  ></DealerDetail>
                </div>
              </If>

              <If
                condition={
                  this.props.orderDetails &&
                  this.props.orderDetails.carSource ==
                    dealerTypeConstants.DEALER_TYPE_GS_CAR
                }
              >
                <div
                  className={
                    this.state.selectedIndex === 6
                      ? "tab-pane active"
                      : "tab-pane"
                  }
                >
                  <Inventory
                    inventoryLogDetail={this.props.inventoryLogDetail}
                  />
                </div>
              </If>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  //Load car details and seller info from API
  async componentDidMount() {
    const appointmentId = this.props.match.params.id;
    await this.props.fetchOrderDetail(appointmentId);
  }
}

Seller.propTypes = {};
const mapStateToProps = ({ order, inventory }) => {
  const { inspection } = order;
  const dealerDetails = order
    ? order.order
      ? order.order.detail
        ? order.order.detail.dealerDetails
        : []
      : []
    : [];

  const inventoryLogDetail = inventory ? inventory.inventoryLogDetail : [];
  const orderDetails = order ? (order.order ? order.order.detail : []) : [];
  return {
    order,
    inspection,
    dealerDetails,
    orderDetails,
    inventoryLogDetail
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOrderDetail: OrderActions.fetchOrderDetail,
      getOrderInspectionDetails: OrderActions.fetchOrderInspectionDetail,
      getInventoryLog: appointmentId =>
        InventoryActions.getInventoryLog(appointmentId)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Stock);
