import Types from "../constants/actionTypes";
import { cityApi } from "./../service/api";

const fetchCitiesInit = () => ({
  type: Types.ACTION_FETCH_CITIES_INIT
});

const fetchCitiesSuccess = data => ({
  type: Types.ACTION_FETCH_CITIES_SUCCESS,
  data
});

const fetchCitiesFail = error => ({
  type: Types.ACTION_FETCH_CITIES_FAIL,
  error
});

const fetchCities = params => dispatch => {
  dispatch(fetchCitiesInit());
  return new Promise((resolve, reject) => {
    cityApi
      .getCities(params)
      .then(resp => {
        resolve(resp);
        dispatch(fetchCitiesSuccess(resp));
      })
      .catch(error => {
        dispatch(fetchCitiesFail(error));
        reject(error);
      });
  });
};

export { fetchCities };
