import Types from "../constants/actionTypes";
import { stateApi } from "./../service/api";

const fetchStatesInit = () => ({
  type: Types.ACTION_FETCH_STATES_INIT
});

const fetchStatesSuccess = data => ({
  type: Types.ACTION_FETCH_STATES_SUCCESS,
  data
});

const fetchStatesFail = error => ({
  type: Types.ACTION_FETCH_STATES_FAIL,
  error
});

const fetchStates = () => dispatch => {
  dispatch(fetchStatesInit());
  return new Promise((resolve, reject) => {
    stateApi
      .getStates()
      .then(resp => {
        resolve(resp);
        dispatch(fetchStatesSuccess(resp));
      })
      .catch(error => {
        dispatch(fetchStatesFail(error));
        reject(error);
      });
  });
};

export { fetchStates };
