import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { OrderActions } from "./../../actions";
import * as StockHelper from "./../../helper/stockHelper";

class BasicDetailsForm extends Component {
  state = {
    noOfOwners: "",
    insuranceType: "",
    hypothecation: "",
    transmissionType: "",
    bodyType: "",
    color: "",
    isTopSelling: "",
    warrantyType: "",
    isC24Assured: "",
    cngLpgFitment: ""
  };

  //handle input changes
  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  //handle submit
  handleSubmit = e => {
    e.preventDefault();
    const { detail } = this.props;
    let appointmentId = "";
    if (detail) {
      appointmentId = detail.appointmentId;
    }

    const data = {
      appointmentId: appointmentId,
      noOfOwners: this.state.noOfOwners,
      insuranceType: this.state.insuranceType,
      hypothecation: this.state.hypothecation,
      transmissionType: this.state.transmissionType,
      bodyType: this.state.bodyType,
      color: this.state.color,
      isTopSelling: this.state.isTopSelling ? this.state.isTopSelling : false,
      warrantyType: this.state.warrantyType ? this.state.warrantyType : "",
      isC24Assured: this.state.isC24Assured ? this.state.isC24Assured : false,
      cngLpgFitment: this.state.cngLpgFitment ? this.state.cngLpgFitment : ""
    };
    this.props.updateOrder(data);
  };

  //component will receive props
  componentWillReceiveProps({ detail }) {
    this.setState({
      noOfOwners:
        detail && detail.noOfOwners ? detail.noOfOwners : this.state.noOfOwners,
      insuranceType:
        detail && detail.insuranceType
          ? detail.insuranceType
          : this.state.insuranceType,
      transmissionType:
        detail && detail.transmissionType
          ? detail.transmissionType
          : this.state.transmissionType,
      hypothecation:
        detail && detail.hypothecation
          ? detail.hypothecation
          : this.state.hypothecation,
      bodyType:
        detail && detail.bodyType ? detail.bodyType : this.state.bodyType,
      color: detail && detail.color ? detail.color : this.state.color,
      isTopSelling:
        detail && detail.isTopSelling
          ? detail.isTopSelling
          : this.state.isTopSelling,
      warrantyType:
        detail && detail.warrantyType
          ? detail.warrantyType
          : this.state.warrantyType,
      isC24Assured:
        detail && detail.isC24Assured
          ? detail.isC24Assured
          : this.state.isC24Assured,
      cngLpgFitment:
        detail && detail.cngLpgFitment
          ? detail.cngLpgFitment
          : this.state.cngLpgFitment
    });
  }

  //render
  render() {
    const dropDownArr = this.props.dropDownArr;
    const ownerList = StockHelper.getOwnerDropDown(dropDownArr);
    const insuranceTypeList = StockHelper.getInsuranceTypeDropDown(dropDownArr);
    const hypoList = StockHelper.getHypoDropDown(dropDownArr);
    const transmissionList = StockHelper.getTransmissionDropDown(dropDownArr);
    const bodyTypeList = StockHelper.getBodyTypeDropDown(dropDownArr);
    const warrantyTypeList = StockHelper.getWarrantyTypeDropDown(dropDownArr);
    const cngLpgFitmentList = StockHelper.getCngLpgFitmentDropDown(dropDownArr);

    //render
    return (
      <form
        name="generic-info"
        method="POST"
        onSubmit={e => this.handleSubmit(e)}
      >
        <div className="form-group col-md-12">
          <div className="form-group col-md-5">
            <label>No. of Owner</label>
            <select
              name="noOfOwners"
              className="form-control"
              onChange={e => this.handleInputChange(e)}
              value={this.state.noOfOwners}
            >
              <option key={Math.random(5)} value="">
                {"--select--"}
              </option>
              {ownerList}
            </select>
          </div>

          <div className="form-group col-md-5">
            <label>Insurance Type </label>
            <select
              className="form-control"
              name="insuranceType"
              onChange={e => this.handleInputChange(e)}
              value={this.state.insuranceType}
            >
              <option key={Math.random(5)} value="">
                {"--select--"}
              </option>
              {insuranceTypeList}
            </select>
          </div>

          <div className="form-group col-md-5">
            <label>Under Hypothecation </label>
            <select
              className="form-control"
              name="hypothecation"
              onChange={e => this.handleInputChange(e)}
              value={this.state.hypothecation}
            >
              <option key={Math.random(5)} value="">
                {"--select--"}
              </option>
              {hypoList}
            </select>
          </div>
          <div className="form-group col-md-5">
            <label>Transmission type </label>
            <select
              className="form-control"
              name="transmissionType"
              onChange={e => this.handleInputChange(e)}
              value={this.state.transmissionType}
            >
              <option key={Math.random(5)} value="">
                {"--select--"}
              </option>
              {transmissionList}
            </select>
          </div>
          <div className="form-group col-md-5">
            <label>Body Type </label>
            <select
              className="form-control"
              name="bodyType"
              onChange={e => this.handleInputChange(e)}
              value={this.state.bodyType}
            >
              <option key={Math.random(5)} value="">
                {"--select--"}
              </option>
              {bodyTypeList}
            </select>
          </div>

          <div className="form-group col-md-5">
            <label>Color</label>
            <input
              className="form-control"
              type="text"
              name="color"
              defaultChecked={this.state.chkbox}
              value={this.state.color}
              onChange={e => this.handleInputChange(e)}
            />
          </div>

          <div className="form-group col-md-5">
            <label>Warranty Type </label>
            <select
              className="form-control"
              name="warrantyType"
              onChange={e => this.handleInputChange(e)}
              value={this.state.warrantyType}
            >
              <option key={Math.random(5)} value="">
                {"--select--"}
              </option>
              {warrantyTypeList}
            </select>
          </div>

          <div className="form-group col-md-5">
            <label>CNG / LPG Fitment </label>
            <select
              className="form-control"
              name="cngLpgFitment"
              onChange={e => this.handleInputChange(e)}
              value={this.state.cngLpgFitment}
            >
              <option key={Math.random(5)} value="">
                {"--select--"}
              </option>
              {cngLpgFitmentList}
            </select>
          </div>

          <div className="form-group col-md-5">
            <label>Is Top Selling </label>
            <input
              className="checkbox"
              type="checkbox"
              name="isTopSelling"
              checked={this.state.isTopSelling}
              onChange={e => this.handleInputChange(e)}
            />
          </div>

          <div className="form-group col-md-5">
            <label>Buy Back Guarantee</label>
            <input
              className="checkbox"
              type="checkbox"
              name="isC24Assured"
              checked={this.state.isC24Assured}
              onChange={e => this.handleInputChange(e)}
            />
          </div>
        </div>

        <div className="form-group col-md-8">
          <div className="form-group col-md-5">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </form>
    );
  }
}

BasicDetailsForm.propTypes = {};
const mapStateToProps = ({
  order: {
    order: { detail, updatableAndAcceptedValues: dropDownArr }
  }
}) => {
  return {
    detail,
    dropDownArr
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateOrder: data => OrderActions.updateOrder(data)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicDetailsForm);
