import React, { Component, Fragment } from "react";

const DealerDetails = props => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          <h4>Dealer Details</h4>
        </div>
        <div className="col-md-12">
          <p>
            <b>Name:</b>
            {props.dealerDetails ? props.dealerDetails.name : "N/A"}
          </p>
          <p>
            <b>Mobile Number:</b>
            {props.dealerDetails ? props.dealerDetails.mobileNumber : "N/A"}
          </p>
          <p>
            <b>Email:</b>
            {props.dealerDetails ? props.dealerDetails.email : "N/A"}
          </p>
          <p>
            <b>Dealer Type:</b>
            {props.dealerDetails ? props.dealerDetails.dealerType : "N/A"}
          </p>
          <p>
            <b>Address Line 1:</b>
            {props.dealerDetails ? props.dealerDetails.addressLine1 : "N/A"}
          </p>
          <p>
            <b>Address Line 2:</b>
            {props.dealerDetails ? props.dealerDetails.addressLine2 : "N/A"}
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default DealerDetails;
