import React from "react";

export const Others = props => {
  const {
    steeringSuspensionBrakes: others,
    registrationState,
    registrationCity
  } = props.inspection;

  return (
    <React.Fragment>
      <div className="box-header">
        <h3 className="box-title">Others</h3>
      </div>
      <div className="box-body col-md-12">
        <div className="form-group col-md-3">
          <label>Steering</label>
          <input
            type="text"
            className="form-control"
            value={others ? others.steering : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Suspension</label>
          <input
            type="text"
            className="form-control"
            value={others ? others.suspension : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Brake</label>
          <input
            type="text"
            className="form-control"
            value={others ? others.brakes : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Registration City</label>
          <input
            type="text"
            className="form-control"
            value={registrationCity ? registrationCity : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Registration State</label>
          <input
            type="text"
            className="form-control"
            value={registrationState ? registrationState : ""}
            readOnly={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
