import React, { Component } from "react";
import { Link } from "react-router-dom";

class Sidebar extends Component {
  state = {
    selectedItemIndex: 0
  };

  //defined router headings
  getHeadingList() {
    return {
      0: "Home",
      1: "C2C Orders",
      2: "C2C Stores",
      3: "C2C Dealers",
      4: "C2C Agents"
    };
  }

  //Toggle class to highlight selected menu item
  toggleClass = id => {
    this.setState({ selectedItemIndex: id });
    const headings = this.getHeadingList();
    const heading = headings[id];
    this.props.handleHeading(heading);
  };

  //defined routers
  routers = () => {};

  render() {
    return (
      <aside className="main-sidebar">
        <section className="sidebar">
          <ul className="sidebar-menu tree" data-widget="tree">
            <li
              key={0}
              className={this.state.selectedItemIndex === 0 ? "active" : ""}
              onClick={() => this.toggleClass(0)}
            >
              <Link to="/" className="no-deco">
                <i className="fa fa-area-chart" /> <span>Home</span>
              </Link>
            </li>
            {/* c2c orders starts here */}
            <li
              key={1}
              className={this.state.selectedItemIndex === 1 ? "active" : ""}
              onClick={() => this.toggleClass(1)}
            >
              <Link to="/orders" className="no-deco">
                <i className="fa fa-circle-o" /> <span>C2C Orders</span>
              </Link>
            </li>
            {/* c2c orders ends here */}
            {/* c2c stores nav starts here */}
            <li
              key={2}
              className={this.state.selectedItemIndex === 2 ? "active" : ""}
              onClick={() => this.toggleClass(2)}
            >
              <Link to="/stores" className="no-deco">
                <i className="fa fa-truck" /> <span>C2C Stores</span>
              </Link>
            </li>
            {/* c2c store nav ends here */}
            {/* c2c dealers nav starts here */}
            <li
              key={3}
              className={this.state.selectedItemIndex === 3 ? "active" : ""}
              onClick={() => this.toggleClass(3)}
            >
              <Link to="/dealers" className="no-deco">
                <i className="fa fa-users" />
                <span> Dealers</span>
              </Link>
            </li>
            {/* c2c dealers nav ends here */}
            {/* c2c agents nav starts here */}
            {/* TODO::Remove hide from here */}
            <li
              key={4}
              className={`hide ${
                this.state.selectedItemIndex === 4 ? "active" : ""
              }`}
              onClick={() => this.toggleClass(4)}
            >
              <Link to="/agents" className="no-deco">
                <i className="fa fa-user-secret" />
                <span>Agents</span>
              </Link>
            </li>
            {/* c2c agents nav ends here */}
          </ul>
        </section>
      </aside>
    );
  }
}

export default Sidebar;
