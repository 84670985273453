import React from "react";

export const Engine = props => {
  const { engineTransmission: engine, cngLpgFitment } = props.inspection;

  return (
    <React.Fragment>
      <div className="box-header">
        <h3 className="box-title">Engine</h3>
      </div>
      <div className="box-body col-md-12">
        <div className="form-group col-md-3">
          <label>Engine</label>
          <input
            type="text"
            className="form-control"
            value={engine ? engine.engine : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Battery</label>
          <input
            type="text"
            className="form-control"
            value={engine ? engine.battery : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Coolant</label>
          <input
            type="text"
            className="form-control"
            value={engine ? engine.coolant : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Engine Mounting</label>
          <input
            type="text"
            className="form-control"
            value={engine ? engine.engineMounting : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Engine Sound</label>
          <input
            type="text"
            className="form-control"
            value={engine ? engine.engineSound : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Clutch</label>
          <input
            type="text"
            className="form-control"
            value={engine ? engine.clutch : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Exhaust Smoke</label>
          <input
            type="text"
            className="form-control"
            value={engine ? engine.exhaustSmoke : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Gear Shifting</label>
          <input
            type="text"
            className="form-control"
            value={engine ? engine.gearShifting : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>CNG / LPG Fitment</label>
          <input
            type="text"
            className="form-control"
            value={cngLpgFitment}
            readOnly={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Engine;
