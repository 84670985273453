import Types from "../constants/actionTypes";
import { uploadApi } from "./../service/api";

const uploadFileInit = () => ({
  type: Types.ACTION_FILE_UPLOAD_INIT
});

const uploadFileSuccess = data => ({
  type: Types.ACTION_FILE_UPLOAD_SUCCESS,
  data
});

const uploadFileFail = error => ({
  type: Types.ACTION_FILE_UPLOAD_FAIL,
  error
});

const uploadFile = data => dispatch => {
  dispatch(uploadFileInit());
  return new Promise((resolve, reject) => {
    uploadApi
      .uploadFile(data)
      .then(resp => {
        resolve(resp);
        dispatch(uploadFileSuccess(resp));
      })
      .catch(error => {
        dispatch(uploadFileFail(error));
        reject(error);
      });
  });
};

export { uploadFile };
