import * as httpService from "./httpService";
import { config } from "./../config/local";
import { httpConstants } from "./../constants/httpConstants";

//get states
export async function getStates() {
  const host = config.api.c2c.host;
  const path = config.api.c2c.state.path;
  const url = host.concat(path);
  const response = await httpService.get(url);
  if (response && response.status === httpConstants.HTTP_STATUS_OK) {
    return response.data ? response.data : [];
  } else {
    return [];
  }
}
