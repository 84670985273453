import React from "react";

//Get owner list
export function getOwnerDropDown(list) {
  if (list && list.owner) {
    const owners = list.owner;
    let ownerOptions = owners.map(owner => {
      return (
        <option key={Math.random(5)} value={owner}>
          {owner}
        </option>
      );
    });

    return ownerOptions;
  } else {
    return "";
  }
}

//Get owner list
export function getInsuranceTypeDropDown(list) {
  if (list && list.insuranceType) {
    const insuranceTypes = list.insuranceType;
    let insuranceTypeOptions = insuranceTypes.map(insuranceType => {
      return (
        <option key={Math.random(5)} value={insuranceType}>
          {insuranceType}
        </option>
      );
    });

    return insuranceTypeOptions;
  } else {
    return "";
  }
}

//Get owner list
export function getRcFitmentDropDown(list) {
  if (list && list.owner) {
    const owners = list.owner;
    let ownerOptions = owners.map(owner => {
      return (
        <option key={Math.random(5)} value={owner}>
          {owner}
        </option>
      );
    });

    return ownerOptions;
  } else {
    return "";
  }
}

//Get owner list
export function getHypoDropDown(list) {
  if (list && list.hypothecation) {
    const hypothecations = list.hypothecation;
    let hypothecationOptions = hypothecations.map(hypothecation => {
      return (
        <option key={Math.random(5)} value={hypothecation}>
          {hypothecation}
        </option>
      );
    });

    return hypothecationOptions;
  } else {
    return "";
  }
}

//Get owner list
export function getTransmissionDropDown(list) {
  if (list && list.transmissionType) {
    const transmissionTypes = list.transmissionType;
    let transmissionTypeOptions = transmissionTypes.map(transmissionType => {
      return (
        <option key={Math.random(5)} value={transmissionType}>
          {transmissionType}
        </option>
      );
    });

    return transmissionTypeOptions;
  } else {
    return "";
  }
}

//Get owner list
export function getBodyTypeDropDown(list) {
  if (list && list.bodyType) {
    const bodyTypes = list.bodyType;
    let bodyTypeOptions = bodyTypes.map(bodyType => {
      return (
        <option key={Math.random(5)} value={bodyType}>
          {bodyType}
        </option>
      );
    });

    return bodyTypeOptions;
  } else {
    return "";
  }
}

//Get warranty type list
export function getWarrantyTypeDropDown(list) {
  if (list && list.warrantyTypes) {
    const warrantyTypes = list.warrantyTypes;
    let warrantyTypeOptions = warrantyTypes.map(warrantyType => {
      return (
        <option key={Math.random(5)} value={warrantyType}>
          {warrantyType}
        </option>
      );
    });

    return warrantyTypeOptions;
  } else {
    return "";
  }
}

//Get CngLpgFitment type list
export function getCngLpgFitmentDropDown(list) {
  if (list && list.cngLpgFitment) {
    const cngLpgFitments = list.cngLpgFitment;
    let cngLpgFitmentOptions = cngLpgFitments.map(cngLpgFitment => {
      return (
        <option key={Math.random(5)} value={cngLpgFitment}>
          {cngLpgFitment}
        </option>
      );
    });

    return cngLpgFitmentOptions;
  } else {
    return "";
  }
}

//format date string as per given format
export function formatDateString(dateString, format = "YYYY-MM-DD") {
  let date = "";
  if (!dateString) {
    return date;
  }
  const dateObj = new Date(dateString);
  switch (format) {
    case "YYYY/MM/DD":
      date =
        dateObj.getFullYear() +
        "/" +
        pad(dateObj.getMonth()) +
        "/" +
        pad(dateObj.getDay());
      break;
    default:
      date =
        dateObj.getFullYear() +
        "-" +
        pad(dateObj.getMonth()) +
        "-" +
        pad(dateObj.getDay());
  }
  return date;
}

//Add leading zeros in date format
function pad(n) {
  return n < 10 ? "0" + n : n;
}
