import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as util from "../../helper/util";
import { documentConstants } from "../../constants/constants";
import { toast } from "react-toastify";
import { If, Then, Else } from "react-if";
import { AgentActions, UploadActions } from "../../actions";
import { uploadApi } from "../../service/api";
import { withRouter } from "react-router-dom";
import _ from "lodash";

class KycDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agentId: "",
      agentCode: "",
      beneficiaryName: "",
      beneficiaryBankName: "",
      bankAccountNumber: "",
      bankIfscCode: "",
      aadharCardNumber: "",
      panCardNumber: "",
      relationship: "",
      isRelatedToCars24: "",
      isGSTCertificate: "",
      gstNumber: "",
      uploadedDocuments: {
        [documentConstants.NON_GST_CERTIFICATE]: {},
        [documentConstants.GST_CERTIFICATE]: {},
        [documentConstants.PAN_CARD]: {},
        [documentConstants.AADHAR_CARD]: {},
        [documentConstants.DECLARATION_FORM]: {},
        [documentConstants.BANK_DOCUMENT]: {},
        [documentConstants.EMPANELMENT_FORM]: {}
      }
    };
    this.fileUploadPath = "/upload";
  }

  componentDidMount() {}

  componentWillReceiveProps({ agentKycDetails, agentId }) {
    if (agentId) {
      this.setState({ agentId });
    }
    if (agentKycDetails) {
      this.setState(agentKycDetails);
    }
  }

  // Handle File input change
  handleFileInputChange = async event => {
    const name = event.target.name;
    const value = event.target.files[0];
    if (util.isFileImage(value) || util.isFilePdf(value)) {
      let fileUploadResponse = await uploadApi.uploadFile(
        this.fileUploadPath,
        {},
        event.target.files[0]
      );
      let imageUrl = fileUploadResponse ? fileUploadResponse.imageUrl : "";
      let imageName = fileUploadResponse ? fileUploadResponse.imageName : "";
      this.setState({
        uploadedDocuments: {
          ...this.state.uploadedDocuments,
          [name]: {
            agentId: this.state.id,
            agentDocType: name,
            imageName: imageName,
            imageUrl: imageUrl
          }
        }
      });
    } else {
      toast.error("Only image and pdf are allowed!");
      event.target.value = "";
      return;
    }
  };

  //Handle input change
  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  //Handle submit
  handleKycDetailSubmit = event => {
    event.preventDefault();
    const data = {
      agentKycDetails: {
        agentId: this.state.agentId,
        agentCode: this.state.agentCode,
        beneficiaryName: this.state.beneficiaryName,
        beneficiaryBankName: this.state.beneficiaryBankName,
        bankAccountNumber: this.state.bankAccountNumber,
        bankIfscCode: this.state.bankIfscCode,
        aadharCardNumber: this.state.aadharCardNumber,
        panCardNumber: this.state.panCardNumber,
        relationship: this.state.relationship,
        isRelatedToCars24: this.state.isRelatedToCars24
          ? this.state.isRelatedToCars24
          : false,
        isGSTCertificate: this.state.isGSTCertificate
          ? this.state.isGSTCertificate
          : false,
        gstNumber: this.state.gstNumber,
        uploadedDocuments: this.state.uploadedDocuments
      }
    };
    this.props.addAgent(data).then();
  };

  render() {
    if (!this.state.agentId) {
      return (
        <div>
          <h4>Fill agent basic details first!</h4>
        </div>
      );
    }

    let docViewList = "";
    let uploadedDocuments = this.state.uploadedDocuments;
    docViewList = _.map(uploadedDocuments, (row, key) => {
      return (
        <If key={key} condition={row.imageUrl}>
          <Then>
            <tr key={key}>
              <td>{util.titleCase(key)}</td>
              <td>
                <a
                  href={row.imageUrl}
                  className="btn btn-success"
                  target="_blank"
                >
                  View
                </a>
              </td>
            </tr>
          </Then>
        </If>
      );
    });

    return (
      <form
        name="kycDetail"
        autoComplete="off"
        onSubmit={e => this.handleKycDetailSubmit(e)}
        encType="multipart/form-data"
      >
        <div className="row">
          <div className="form-group col-md-3">
            <label>Beneficiary Name *</label>
            <input
              name="beneficiaryName"
              type="text"
              autoComplete="off"
              className="form-control"
              value={this.state.beneficiaryName}
              onChange={e => this.handleInputChange(e)}
              required
            />
          </div>
          <div className="form-group col-md-3">
            <label>Beneficiary Bank Name *</label>
            <input
              name="beneficiaryBankName"
              type="text"
              autoComplete="off"
              className="form-control"
              value={this.state.beneficiaryBankName}
              onChange={e => this.handleInputChange(e)}
              required
            />
          </div>

          <div className="form-group col-md-3">
            <label>Account Number *</label>
            <input
              name="bankAccountNumber"
              type="text"
              autoComplete="off"
              className="form-control"
              value={this.state.bankAccountNumber}
              onChange={e => this.handleInputChange(e)}
              required
            />
          </div>

          <div className="form-group col-md-3">
            <label>IFSC Code *</label>
            <input
              name="bankIfscCode"
              type="text"
              autoComplete="off"
              className="form-control"
              value={this.state.bankIfscCode}
              onChange={e => this.handleInputChange(e)}
              required
              pattern="^[A-Za-z]{4}\d{7}$"
              title="First four characters and last seven digits"
              maxLength={11}
            />
          </div>
          <div className="form-group col-md-3">
            <label>Cheque copy/ Passbook copy *</label>

            <input
              type="file"
              name={documentConstants.BANK_DOCUMENT}
              className="form-control"
              onChange={e => this.handleFileInputChange(e)}
              accept="image/*,application/pdf"
              required={
                this.state.uploadedDocuments[documentConstants.BANK_DOCUMENT] &&
                this.state.uploadedDocuments[documentConstants.BANK_DOCUMENT]
                  .imageUrl
                  ? false
                  : true
              }
            />
          </div>
          <div className="form-group col-md-3">
            <label>Aadhar Card Number *</label>
            <input
              name="aadharCardNumber"
              type="text"
              autoComplete="off"
              className="form-control"
              value={this.state.aadharCardNumber}
              onChange={e => this.handleInputChange(e)}
              maxLength={12}
              required
              pattern=".{12,12}"
              title="12 digits are required"
            />
          </div>

          <div className="form-group col-md-3">
            <label>Aadhar card (Self Attested) * </label>
            <input
              type="file"
              name={documentConstants.AADHAR_CARD}
              className="form-control"
              onChange={e => this.handleFileInputChange(e)}
              accept="image/*,application/pdf"
              required={
                this.state.uploadedDocuments[documentConstants.AADHAR_CARD] &&
                this.state.uploadedDocuments[documentConstants.AADHAR_CARD]
                  .imageUrl
                  ? false
                  : true
              }
            />
          </div>
          <div className="form-group col-md-3">
            <label>Empanelment Form *</label>
            <input
              type="file"
              name={documentConstants.EMPANELMENT_FORM}
              className="form-control"
              onChange={e => this.handleFileInputChange(e)}
              accept="image/*,application/pdf"
              required={
                this.state.uploadedDocuments[
                  documentConstants.EMPANELMENT_FORM
                ] &&
                this.state.uploadedDocuments[documentConstants.EMPANELMENT_FORM]
                  .imageUrl
                  ? false
                  : true
              }
            />
          </div>

          <div className="form-group col-md-3">
            <label>PAN Card Number *</label>
            <input
              name="panCardNumber"
              type="text"
              autoComplete="off"
              className="form-control"
              value={this.state.panCardNumber}
              onChange={e => this.handleInputChange(e)}
              required
              maxLength={10}
            />
          </div>

          <div className="form-group col-md-3">
            <label>PAN Card (Self Attested) *</label>
            <input
              type="file"
              name={documentConstants.PAN_CARD}
              className="form-control"
              onChange={e => this.handleFileInputChange(e)}
              accept="image/*,application/pdf"
              required={
                this.state.uploadedDocuments[documentConstants.PAN_CARD] &&
                this.state.uploadedDocuments[documentConstants.PAN_CARD]
                  .imageUrl
                  ? false
                  : true
              }
            />
          </div>

          <div className="form-group col-md-3">
            <label>Signed Declaration or E-Mail PDF *</label>
            <input
              type="file"
              name={documentConstants.DECLARATION_FORM}
              className="form-control"
              onChange={e => this.handleFileInputChange(e)}
              accept="image/*,application/pdf"
              required={
                this.state.uploadedDocuments[
                  documentConstants.DECLARATION_FORM
                ] &&
                this.state.uploadedDocuments[documentConstants.DECLARATION_FORM]
                  .imageUrl
                  ? false
                  : true
              }
            />
          </div>

          <div className="form-group col-md-3">
            <label>
              Whether the Party has a relationship with any of the Cars24/Key
              Managerial Personnel?
            </label>
            <input
              type="checkbox"
              name="isRelatedToCars24"
              checked={this.state.isRelatedToCars24}
              onChange={e => this.handleInputChange(e)}
            ></input>
          </div>

          <div className="form-group col-md-3">
            <label>Relationship Details</label>
            <input
              name="relationship"
              type="text"
              autoComplete="off"
              className="form-control"
              value={this.state.relationship}
              onChange={e => this.handleInputChange(e)}
            />
          </div>

          <div className="form-group col-md-3">
            <label>Whether Person has GST Certificate?</label>
            <input
              type="checkbox"
              name="isGSTCertificate"
              checked={this.state.isGSTCertificate}
              onChange={e => this.handleInputChange(e)}
            ></input>
          </div>

          <If condition={this.state.isGSTCertificate}>
            <Then>
              <div className="form-group col-md-3">
                <label>GST Number *</label>
                <input
                  name="gstNumber"
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  value={this.state.gstNumber}
                  onChange={e => this.handleInputChange(e)}
                  required
                />
              </div>

              <div className="form-group col-md-3">
                <label>GST document*</label>
                <input
                  type="file"
                  name={documentConstants.GST_CERTIFICATE}
                  className="form-control"
                  onChange={e => this.handleFileInputChange(e)}
                  accept="image/*,application/pdf"
                  required={
                    this.state.uploadedDocuments[
                      documentConstants.GST_CERTIFICATE
                    ] &&
                    this.state.uploadedDocuments[
                      documentConstants.GST_CERTIFICATE
                    ].imageUrl
                      ? false
                      : true
                  }
                />
              </div>
            </Then>
            <Else>
              <div className="form-group col-md-3">
                <label>Non GST document*</label>
                <input
                  type="file"
                  name={documentConstants.NON_GST_CERTIFICATE}
                  className="form-control"
                  onChange={e => this.handleFileInputChange(e)}
                  accept="image/*,application/pdf"
                  required={
                    this.state.uploadedDocuments[
                      documentConstants.NON_GST_CERTIFICATE
                    ] &&
                    this.state.uploadedDocuments[
                      documentConstants.NON_GST_CERTIFICATE
                    ].imageUrl
                      ? false
                      : true
                  }
                />
              </div>
            </Else>
          </If>

          <div className="clearfix"></div>
          <div className="form-group col-md-12">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>

          <div className="col-md-12">
            <h4>Uploaded Documents</h4>
            <table className="table">
              <thead>
                <tr>
                  <th>DocName</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>{docViewList}</tbody>
            </table>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({ agent: { detail } }) => {
  const { agentKycDetails } = detail;
  const agentId = detail.agentBasicDetails
    ? detail.agentBasicDetails
      ? detail.agentBasicDetails.agentId
      : ""
    : "";
  return {
    agentId,
    agentKycDetails
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      uploadFile: data => UploadActions.uploadFile(data),
      addAgent: data => AgentActions.addAgent(data)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(KycDetail));
