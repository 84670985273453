import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import * as gridHelper from "./../../helper/mainGridHelper";
import LinkRenderer from "../common/grid/linkRenderer";
import LabelRenderer from "../common/grid/labelRenderer";
import DropDwonFilter from "../common/grid/dropDwonFilter";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Paginator from "../common/grid/paginator";
import { agentApi } from "./../../service/api";
import { Link } from "react-router-dom";
import { getAgentStatus } from "../../service/eav";

class AgentGrid extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      totalPages: 0,
      recordsOnPage: 0,
      totalRecords: 0,
      filterApplied: false,
      defaultColDef: {},
      columnDefs: [
        {
          headerName: "Agent ID",
          field: "agentId",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            caseSensitive: false,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          },
          cellRenderer: "LinkRenderer"
        },
        {
          headerName: "Name",
          field: "agentName",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            caseSensitive: false,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          }
        },
        {
          headerName: "Phone Number",
          field: "phone",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            caseSensitive: false,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          }
        },
        {
          headerName: "Created On",
          field: "createdOn",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            caseSensitive: false,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          }
        },
        {
          headerName: "City",
          field: "agentCity",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            caseSensitive: false,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          }
        },
        {
          headerName: "Status",
          field: "agentStatus",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            caseSensitive: false,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          },
          floatingFilterComponent: "DropDwonFilter",
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            name: "status",
            options: this.getParsedAgentStatus()
          },
          cellRenderer: "LabelRenderer",
          cellRendererParams: {
            options: this.getParsedAgentStatus()
          }
        }
      ],
      context: { componentParent: this },
      frameworkComponents: {
        LinkRenderer: LinkRenderer,
        LabelRenderer: LabelRenderer,
        DropDwonFilter: DropDwonFilter
      }
    };
  }

  getParsedAgentStatus = () => {
    let agentStatusData = [];
    let agentStatus = getAgentStatus();
    if (agentStatus) {
      agentStatusData = Object.keys(agentStatus).map(function(key) {
        return {
          key: key,
          value: agentStatus[key]
        };
      });
    }
    return agentStatusData;
  };

  render() {
    this.getParsedAgentStatus();
    return (
      <React-Fragement>
        <div className="row">
          <div className="col-md-1 pull-right">
            <Link to="/agents/add">
              <button className="btn btn-success" type="button">
                Add
              </button>
            </Link>
          </div>
          <br />
        </div>
        <div className="ag-theme-balham ag-grid-outer-wrapper">
          <AgGridReact
            columnDefs={this.state.columnDefs}
            rowData={this.state.rowData}
            defaultColDef={this.state.defaultColDef}
            context={this.state.context}
            paginationPageSize={10}
            floatingFilter
            onFilterChanged={this.handleFilterChanged}
            frameworkComponents={this.state.frameworkComponents}
            onGridReady={this.onGridReady}
            enableCellTextSelection={true}
          />
          <Paginator
            page={this.state.page}
            totalPages={this.state.totalPages}
            totalRecords={this.state.totalRecords}
            recordsOnPage={this.state.recordsOnPage}
            onClickPagination={this.navigatePagination}
          />
        </div>
      </React-Fragement>
    );
  }

  //Load agents on component mount
  async componentDidMount() {
    this._isMounted = true;
    const {
      response: agents,
      page,
      totalPages,
      totalRecords
    } = await agentApi.getAgents();
    if (this._isMounted) {
      this.setState({
        rowData: agents,
        page: page,
        totalPages: totalPages,
        totalRecords: totalRecords,
        recordsOnPage: agents ? agents.length : 0
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //Clear all filters
  clearFilters = () => {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  };

  //Redirect to agent detail page
  redirectToDetailsPage(id) {
    this.props.history.push("/agents/" + id);
  }

  //Get name of unique identifier for each row
  getUniqueIdName() {
    return "agentId";
  }

  //Set grid and columns
  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  //Handle grid filters
  handleFilterChanged = () => {
    this.resetGridWithNewData();
  };

  //Navigate pagination
  navigatePagination = async pageNo => {
    const model = { ...this.gridApi.getFilterModel() };
    const query = gridHelper.createFilterQuery(model);
    const {
      response: agents,
      page,
      totalPages,
      totalRecords
    } = await agentApi.getAgents(query, pageNo);
    this.setState({
      rowData: agents,
      page: page,
      totalPages: totalPages,
      totalRecords: totalRecords,
      recordsOnPage: agents ? agents.length : 0
    });
    if (query) {
      this.gridApi.setFilterModel(model);
    }
    this.setState({ filterApplied: true });
  };

  //Reset grid with newly fetched data
  resetGridWithNewData = async pageNo => {
    if (!this.state.filterApplied) {
      const model = { ...this.gridApi.getFilterModel() };
      const query = gridHelper.createFilterQuery(model);
      const {
        response: agents,
        page,
        totalPages,
        totalRecords
      } = await agentApi.getAgents(query, pageNo);
      this.setState({
        rowData: agents,
        page: page,
        totalPages: totalPages,
        totalRecords: totalRecords,
        recordsOnPage: agents ? agents.length : 0
      });
      if (query) {
        this.gridApi.setFilterModel(model);
      }
      this.setState({ filterApplied: true });
    } else {
      this.setState({ filterApplied: false });
    }
  };
}

export default AgentGrid;
