import React, { Component } from "react";
import * as LoginHelper from "./../../helper/loginHelper";

class Login extends Component {
  //Handle login redirect to OMS login page
  handleLoginRedirect = () => {
    LoginHelper.redirectToLoginServer();
    return false;
  };

  //render login box
  render() {
    return (
      <div className="login-wrapper">
        <div className="login-container">
          <span className="admin-login-title">
            C2C Admin <span>PANEL</span>
          </span>

          <div className="admin-login-box">
            <div className="left-login-bx">
              <div className="admin-btn-wrap">
                <input
                  onClick={this.handleLoginRedirect}
                  name="submit"
                  type="button"
                  className="admin-login-btn"
                  value="Login with admin panel"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
