import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { OrderActions } from "./../../actions";

class Publish extends Component {
  state = {
    orderState: "",
    despositions: "",
    despositionList: [],
    remarks: "",
    soldPrice: "",
    showSoldPrice: false
  };

  //get child drop down list
  getChildDropDown = orderState => {
    if (orderState === "") {
      return [];
    }
    const despositions = this.props.despositions;
    let despositionListObj = despositions[orderState];
    let despositionList = Object.keys(despositionListObj).map(desposition => {
      return despositionListObj[desposition];
    });
    return despositionList;
  };

  //handle input change
  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "orderState") {
      const despositionList = this.getChildDropDown(value);
      let obj = {
        orderState: value,
        despositionList: despositionList
      };
      if (value === "SOLD") {
        obj.showSoldPrice = true;
      } else {
        obj.showSoldPrice = false;
      }

      this.setState(obj);
    } else {
      this.setState({
        [name]: value
      });
    }
  };

  //update order state
  updateOrderState = () => {
    let appointmentId =
      this.props.order && this.props.order.detail
        ? this.props.order.detail.appointmentId
        : "";

    let data = {
      appointmentId: appointmentId,
      orderState: this.state.despositions,
      remarks: this.state.remarks
    };

    //Send sold price in case of SOLD
    if (this.state.orderState === "SOLD") {
      data = { ...data, ...{ soldPrice: this.state.soldPrice } };
    }
    this.props.updateOrderState(data);
  };

  render() {
    if (
      this.props.despositions === {} ||
      this.props.despositions === undefined ||
      this.props.despositions === null
    ) {
      return "<p>No Data</p>";
    }

    const despositionsObj = this.props.despositions;
    let stateOptions = Object.keys(despositionsObj).map(desposition => {
      return (
        <option key={Math.random(5)} value={desposition}>
          {desposition}
        </option>
      );
    });

    let despositionOptions = Object.keys(this.state.despositionList).map(
      desposition => {
        let element = this.state.despositionList[desposition];
        return (
          <option key={Math.random(5)} value={element.statusCode}>
            {element.desposition}
          </option>
        );
      }
    );

    //render
    return (
      <div className="form-group col-md-8">
        <div className="form-group col-md-8">
          <label>Order C2C State </label>
          <select
            name="orderState"
            className="form-control"
            onChange={e => this.handleInputChange(e)}
            value={this.state.orderState}
          >
            <option key={Math.random(5)} value="">
              {"--select--"}
            </option>
            {stateOptions}
          </select>
        </div>

        <div className="form-group col-md-8">
          <label>Dispositions </label>
          <select
            className="form-control"
            name="despositions"
            onChange={e => this.handleInputChange(e)}
            value={this.state.despositions}
          >
            <option key={Math.random(5)} value="">
              {"--select--"}
            </option>
            {despositionOptions}
          </select>
        </div>
        <div
          className={this.state.showSoldPrice ? "form-group col-md-8" : "hide"}
        >
          <label>Sold Price </label>
          <input
            className="form-control"
            type="number"
            name="soldPrice"
            value={this.state.soldPrice}
            onChange={e => this.handleInputChange(e)}
          />
        </div>
        <div className="form-group col-md-8">
          <label>Remarks </label>
          <textarea
            className="form-control"
            type="text"
            name="remarks"
            value={this.state.remarks}
            onChange={e => this.handleInputChange(e)}
          />
        </div>

        <div className="form-group col-md-8">
          <button
            onClick={() => this.updateOrderState()}
            className="btn btn-primary"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

Publish.propTypes = {};
const mapStateToProps = ({ order: { order, despositions } }) => {
  return {
    order,
    despositions
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateOrderState: data => OrderActions.updateOrderState(data)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Publish);
