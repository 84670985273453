import React from "react";

export const Exterior = props => {
  const { exteriorTyres: exterior } = props.inspection;

  return (
    <React.Fragment>
      <div className="box-header">
        <h3 className="box-title">Exterior</h3>
      </div>
      <div className="box-body col-md-12">
        <div className="form-group col-md-3">
          <label>Bumpers</label>
          <input
            type="text"
            className="form-control"
            value={exterior ? exterior.bumpers : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Bonnet Hood</label>
          <input
            type="text"
            className="form-control"
            value={exterior ? exterior.bonnetHood : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Bonnet Patti </label>
          <input
            type="text"
            className="form-control"
            value={exterior ? exterior.bonnetPatti : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Roof</label>
          <input
            type="text"
            className="form-control"
            value={exterior ? exterior.roof : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Fenders</label>
          <input
            type="text"
            className="form-control"
            value={exterior ? exterior.fenders : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Doors</label>
          <input
            type="text"
            className="form-control"
            value={exterior ? exterior.doors : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Lights</label>
          <input
            type="text"
            className="form-control"
            value={exterior ? exterior.lights : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Dicky Door / Boot Door</label>
          <input
            type="text"
            className="form-control"
            value={exterior ? exterior.dickyDoorBootDoor : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>ORVM - Manual / Electrical</label>
          <input
            type="text"
            className="form-control"
            value={exterior ? exterior.orvm : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Windshield</label>
          <input
            type="text"
            className="form-control"
            value={exterior ? exterior.windshield : ""}
            readOnly={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
