import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { OrderActions, StoreActions } from "./../../actions";
import { If, Then, Else } from "react-if";
import { carSourceConstants } from "../../constants/constants";

class Seller extends Component {
  state = {
    ownerName: "",
    ownerPrimaryPhone: "",
    email: "",
    owner_address_line_1: "",
    owner_address_line_2: "",
    landmark: "",
    car24Price: "",
    listingPrice: "",
    isTopSelling: false,
    storeId: "",
    stores: [],
    customStores: [],
    carSource: ""
  };

  //component will receive props
  componentWillReceiveProps({ detail }) {
    if (detail) {
      this.setState(
        {
          ownerName: detail.ownerName ? detail.ownerName : this.state.ownerName,
          ownerPrimaryPhone: detail.ownerPrimaryPhone
            ? detail.ownerPrimaryPhone
            : this.state.ownerPrimaryPhone,
          email: detail.email ? detail.email : this.state.email,
          owner_address_line_1: detail.owner_address_line_1
            ? detail.owner_address_line_1
            : this.state.owner_address_line_1,
          owner_address_line_2: detail.owner_address_line_2
            ? detail.owner_address_line_2
            : this.state.owner_address_line_2,
          landmark: detail.landmark ? detail.landmark : this.state.landmark,
          car24Price: detail.car24Price
            ? detail.car24Price
            : this.state.car24Price,
          listingPrice: detail.listingPrice
            ? detail.listingPrice
            : this.state.listingPrice,
          isTopSelling: detail.isTopSelling
            ? detail.isTopSelling
            : this.state.isTopSelling,
          storeId: detail.storeId ? detail.storeId : this.state.storeId,
          carSource: detail.carSource ? detail.carSource : this.state.carSource
        },
        async function() {
          if (this.state.isTopSelling) {
            let storesData = await this.props.getStores({
              data: "all",
              status: "1"
            });
            let stores = storesData.response ? storesData.response : [];
            let customStores = [];
            stores.map(store => {
              customStores[store.id] = {
                ownerName: store.storeName,
                ownerPrimaryPhone: store.storePhoneNumber,
                owner_address_line_1: store.address
              };
            });
            this.setState({ stores: stores, customStores: customStores });
          }
        }
      );
    }
  }

  //handle input changes
  handleSellerInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value
      },
      function() {
        if (name === "storeId") {
          let customStores = this.state.customStores;
          this.setState({
            ownerName: customStores[this.state.storeId].ownerName,
            ownerPrimaryPhone:
              customStores[this.state.storeId].ownerPrimaryPhone,
            owner_address_line_1:
              customStores[this.state.storeId].owner_address_line_1
          });
        }
      }
    );
  };

  //Update car source to GS_CAR
  updateCarSource = () => {
    let data = {
      inventoryInfo: {
        appointmentId: this.props.detail.appointmentId,
        carSource: carSourceConstants.GS_CAR
      }
    };
    this.props.updateCarSource(data);
  };

  //handle form submit
  handleSubmit = e => {
    e.preventDefault();
    const appointmentId = this.props.detail
      ? this.props.detail.appointmentId
      : "";
    const data = { ...this.state, ...{ appointmentId: appointmentId } };
    this.props.updateOrder(data);
  };

  render() {
    if (this.props.detail === undefined || this.props.detail === null) {
      return <p>No Data</p>;
    }

    let stores = this.state.stores;
    let storesList = "";
    if (stores && stores.length > 0) {
      storesList = stores.map(store => {
        return (
          <Fragment>
            <option key={store.id} value={store.id}>
              {store.storeName !== "" ? store.storeName : "N/A"}
            </option>
          </Fragment>
        );
      });
    }
    //Render
    return (
      <form autoComplete="off" onSubmit={e => this.handleSubmit(e)}>
        {/* <div className="form-group col-md-6">
          <If condition={this.state.carSource == carSourceConstants.GS_CAR}>
            <Then>
              <h4>Stores:</h4>
              <select
                name="storeId"
                className="form-control store"
                value={this.state.storeId}
                onChange={e => this.handleSellerInputChange(e)}
              >
                {storesList}
              </select>
            </Then>
            <Else></Else>
          </If>
        </div> */}
        <If
          condition={this.state.carSource == carSourceConstants.CUSTOMER_CARE}
        >
          <Then>
            <div className="form-group col-md-2 pull-right">
              <a
                href="#"
                onClick={this.updateCarSource}
                className="btn btn-warning"
              >
                Convert To GS Car
              </a>
            </div>
          </Then>
          <Else></Else>
        </If>

        <div className="clearfix"></div>
        <div className="form-group col-md-3">
          <label>Seller Name </label>
          <input
            name="ownerName"
            type="text"
            className="form-control"
            autoComplete="off"
            value={this.state.ownerName}
            onChange={e => this.handleSellerInputChange(e)}
            readOnly={
              this.state.carSource == carSourceConstants.C2C_DEALER_CAR ||
              this.state.carSource == carSourceConstants.GS_CAR
                ? "readonly"
                : false
            }
          />
        </div>
        <div className="form-group col-md-3">
          <label>Seller Mobile Number </label>
          <input
            name="ownerPrimaryPhone"
            type="number"
            autoComplete="off"
            className="form-control"
            value={this.state.ownerPrimaryPhone}
            readOnly={
              this.state.carSource == carSourceConstants.C2C_DEALER_CAR ||
              this.state.carSource == carSourceConstants.GS_CAR
                ? "readonly"
                : false
            }
            onChange={e => this.handleSellerInputChange(e)}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Seller Email</label>
          <input
            name="email"
            type="text"
            autoComplete="off"
            className="form-control"
            value={this.state.email}
            readOnly={
              this.state.carSource == carSourceConstants.C2C_DEALER_CAR ||
              this.state.carSource == carSourceConstants.GS_CAR
                ? "readonly"
                : false
            }
            onChange={e => this.handleSellerInputChange(e)}
          />
        </div>

        <div className="form-group col-md-3">
          <label>Landmark </label>
          <input
            name="landmark"
            type="text"
            autoComplete="off"
            className="form-control"
            value={this.state.landmark}
            readOnly={
              this.state.carSource == carSourceConstants.C2C_DEALER_CAR ||
              this.state.carSource == carSourceConstants.GS_CAR
                ? "readonly"
                : false
            }
            onChange={e => this.handleSellerInputChange(e)}
          />
        </div>
        <div className="form-group col-md-3">
          <label>C2C Quote </label>
          <input
            name="car24Price"
            type="number"
            autoComplete="off"
            className="form-control"
            value={this.state.car24Price}
            onChange={e => this.handleSellerInputChange(e)}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Listing Price </label>
          <input
            name="listingPrice"
            type="number"
            autoComplete="off"
            className="form-control"
            value={this.state.listingPrice}
            onChange={e => this.handleSellerInputChange(e)}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Current Address Line 1</label>
          <textarea
            name="owner_address_line_1"
            type="text"
            autoComplete="off"
            className="form-control"
            value={this.state.owner_address_line_1}
            readOnly={
              this.state.carSource == carSourceConstants.C2C_DEALER_CAR ||
              this.state.carSource == carSourceConstants.GS_CAR
                ? "readonly"
                : false
            }
            onChange={e => this.handleSellerInputChange(e)}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Current Address Line 2</label>
          <textarea
            name="owner_address_line_2"
            type="text"
            autoComplete="off"
            className="form-control"
            value={this.state.owner_address_line_2}
            readOnly={
              this.state.carSource == carSourceConstants.C2C_DEALER_CAR ||
              this.state.carSource == carSourceConstants.GS_CAR
                ? "readonly"
                : false
            }
            onChange={e => this.handleSellerInputChange(e)}
          />
        </div>
        <div className="form-group col-md-8">
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </div>
      </form>
    );
  }
}

Seller.propTypes = {};
const mapStateToProps = ({
  order: {
    order: { detail }
  }
}) => {
  return {
    detail
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateOrder: data => OrderActions.updateOrder(data),
      getStores: data => StoreActions.fetchAllStores(data),
      updateCarSource: data => OrderActions.updateCarSource(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Seller);
