import { createReducer } from "reduxsauce";
import Types from "../constants/actionTypes";

export const INITIAL_STATE = {
  heading: "Home"
};

export const updateHeading = (state = INITIAL_STATE, { data: heading }) => {
  return { ...state, heading };
};

export const HANDLERS = {
  [Types.ACTION_UPDATE_HEADING]: updateHeading
};

export default createReducer(INITIAL_STATE, HANDLERS);
