let BASE_API_URL = "http://c2c-admin-panel-qa.ninja24.in";
let BASE_AUTH_URL = "http://dealer.local.com";

switch (process.env.REACT_APP_ENV) {
  case "QA1":
    BASE_API_URL = "https://c2c-admin-panel-qa.ninja24.in";
    BASE_AUTH_URL = "http://dealerengine-qa1.ninja24.in";
    break;
  case "LNOD":
    BASE_API_URL = "http://c2c-admin-panel-lnod.ninja24.in";
    BASE_AUTH_URL = "http://dealerengine-lnod.ninja24.in";
    break;
  case "STAGING":
    BASE_API_URL = " https://c2c-admin-panel-stage.cars24.team";
    BASE_AUTH_URL = "https://admin-staging.24c.in";
    break;
  case "PRODUCTION":
    BASE_API_URL = "https://c2c-admin-panel.cars24.team";
    BASE_AUTH_URL = "https://admin.24c.in";
    break;
  default:
    break;
}

export const config = {
  api: {
    c2c: {
      host: BASE_API_URL,
      orders: {
        path: "/orders"
      },
      despositions: {
        path: "/orders/state"
      },
      inventory: {
        path: "/onboard/inventory"
      },
      updateOrderState: {
        path: "/update/orders"
      },
      updateOrder: {
        path: "/orders/update"
      },
      inspection: {
        path: "/orders/inspection"
      },
      stores: {
        path: "/stores"
      },
      city: {
        path: "/cities"
      },
      state: {
        path: "/states"
      },
      dealers: {
        path: "/dealers"
      },
      dealerForm: {
        path: "/dealerForm"
      },
      agents: {
        path: "/agents"
      },
      agentStatusUpdate: {
        path: "/agent/status"
      },
      onboardInventory: {
        path: "/onboard/inventory"
      },
      updateInventory: {
        path: "/update/inventory"
      },
      inventoryLog: {
        path: "/inventory/track"
      },
      c2bOrders: {
        path: "/c2bOrders"
      },
      addC2bOrder: {
        path: "/c2borders/add"
      },
      sellerInspectionDetail: {
        path: "/sellerInspectionDetail"
      }
    },
    oms: {
      host: BASE_AUTH_URL,
      authentication: {
        path: "/users/authentication"
      }
    }
  }
};
