import * as httpService from "./httpService";
import { config } from "./../config/local";

const HTTP_STATUS_OK = 200;

//get cities
export async function getCities(params = {}) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.city.path;
  const url = host.concat(path);
  const response = await httpService.get(url, params);
  if (response && response.status === HTTP_STATUS_OK) {
    return response.data ? response.data : [];
  } else {
    return [];
  }
}
