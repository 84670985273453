import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DealerActions, StateActions, CityActions } from "../../actions";
import { constants, dealerTypeConstants } from "../../constants/constants";
import { Redirect } from "react-router-dom";

class Dealer extends Component {
  state = {
    dealerId: "",
    dealerCode: "",
    name: "",
    mobileNumber: "",
    email: "",
    dealerType: "",
    addressLine1: "",
    addressLine2: "",
    cityId: "",
    stateId: "",
    cities: [],
    states: [],
    dealerMarkupSlabs: [],
    status: 0,
    defaultSlabs: [],
    dealerTypeWithMarkupSlabs: [],
    formAction: constants.FORM_ACTION_SAVE,
    redirect: false
  };

  //Load dealer details from API
  async componentDidMount() {
    const dealerId = this.props.match.params.id;
    if (dealerId) {
      await this.props.fetchDealerDetail(dealerId);
      this.setState({ formAction: constants.FORM_ACTION_UPDATE });
    } else {
      await this.props.fetchDealerForm();
    }

    if (this.state.states.length == 0) {
      const states = await this.props.fetchStates();
      this.setState({ states: states });
      if (this.state.stateId) {
        let cities = await this.props.fetchCities({
          stateId: this.state.stateId
        });
        if (cities && cities.length > 0) {
          this.setState({ cities: cities });
        }
      }
    }
  }

  componentWillReceiveProps({ detail }) {
    if (detail) {
      this.setState(detail);
    }
  }

  //handle submit
  handleSubmit = e => {
    e.preventDefault();
    const { detail } = this.props;
    let dealerId = "";
    if (detail) {
      dealerId = detail.dealerId;
    }

    const data = {
      dealerId: this.state.dealerId,
      dealerCode: this.state.dealerCode,
      name: this.state.name,
      mobileNumber: this.state.mobileNumber,
      email: this.state.email,
      dealerType: this.state.dealerType,
      addressLine1: this.state.addressLine1,
      addressLine2: this.state.addressLine2,
      cityId: this.state.cityId,
      stateId: this.state.stateId,
      status: this.state.status ? 1 : 0,
      dealerMarkupSlabs: this.state.dealerMarkupSlabs
        ? this.state.dealerMarkupSlabs
        : []
    };
    this.props.addDealer(data);
    this.setState({ redirect: true });
  };

  //handle slab change
  handleSlabChange = (event, slab) => {
    if (this.state.dealerMarkupSlabs.length > 0) {
      let dealerMarkupSlabs = this.state.dealerMarkupSlabs;
      dealerMarkupSlabs.forEach((savedSlab, key) => {
        if (savedSlab.start == slab.start && savedSlab.end == slab.end) {
          dealerMarkupSlabs[key] = {
            start: slab.start,
            end: slab.end,
            markUpValue: event.target.value
          };
        }
      });
      this.setState({
        dealerMarkupSlabs
      });
    }
  };

  //handle input changes
  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value
      },
      async function() {
        if (name === "stateId") {
          //TODO:: Make this common as same is used in didMount
          let cities = await this.props.fetchCities({
            stateId: this.state.stateId
          });
          if (cities && cities.length > 0) {
            this.setState({ cities: cities });
          }
        }
        if (name === "dealerType") {
          let dealerTypeWithMarkupSlabs = this.state.dealerTypeWithMarkupSlabs;
          let mappedDealerTypeMarkupSlab = [];
          dealerTypeWithMarkupSlabs.forEach(row => {
            mappedDealerTypeMarkupSlab[row.dealerType] = row.dealerMarkupSlabs;
          });
          this.setState({
            dealerMarkupSlabs: mappedDealerTypeMarkupSlab[value]
          });
        }
      }
    );
  };

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/dealers" />;
    }
    const states = this.state.states;
    let statesList = "";
    if (states && states.length > 0) {
      statesList = states.map(state => {
        return (
          <option key={state.stateId} value={state.stateId}>
            {state.stateName}
          </option>
        );
      });
    }

    const cities = this.state.cities;
    let citiesList = "";
    if (cities && cities.length > 0) {
      citiesList = cities.map(city => {
        return (
          <option key={city.cityId} value={city.cityId}>
            {city.cityName}
          </option>
        );
      });
    }

    let dealerMarkupSlabs = this.state.dealerMarkupSlabs;
    let slabRangeList = "";
    if (dealerMarkupSlabs && dealerMarkupSlabs.length > 0) {
      slabRangeList = dealerMarkupSlabs.map(slab => {
        return (
          <div className="form-group col-md-2">
            <label>
              {slab.start}-{slab.end}
            </label>
            <div>
              <input
                className="form-control"
                type="number"
                name="markUpValue"
                value={slab.markUpValue ? slab.markUpValue : 0}
                onChange={e => this.handleSlabChange(e, slab)}
                required
              />
            </div>
          </div>
        );
      });
    }

    return (
      <form name="dealer" method="POST" onSubmit={e => this.handleSubmit(e)}>
        <div className="form-group col-md-12">
          <div className="form-group col-md-5">
            <label>Dealer Id</label>
            <input
              className="form-control"
              type="text"
              name="dealerId"
              value={this.state.dealerId ? this.state.dealerId : ""}
              onChange={e => this.handleInputChange(e)}
              required
            />
          </div>

          <div className="form-group col-md-5">
            <label>Dealer Code</label>
            <input
              className="form-control"
              type="text"
              name="dealerCode"
              value={this.state.dealerCode ? this.state.dealerCode : ""}
              onChange={e => this.handleInputChange(e)}
              required
            />
          </div>

          <div className="form-group col-md-5">
            <label>Name</label>
            <input
              className="form-control"
              type="text"
              name="name"
              value={this.state.name ? this.state.name : ""}
              onChange={e => this.handleInputChange(e)}
              required
            />
          </div>

          <div className="form-group col-md-5">
            <label>MobileNumber</label>
            <input
              className="form-control"
              type="text"
              name="mobileNumber"
              value={this.state.mobileNumber ? this.state.mobileNumber : ""}
              onChange={e => this.handleInputChange(e)}
              required
              maxLength={10}
            />
          </div>

          <div className="form-group col-md-5">
            <label>Email</label>
            <input
              className="form-control"
              type="text"
              name="email"
              value={this.state.email ? this.state.email : ""}
              onChange={e => this.handleInputChange(e)}
              required
            />
          </div>

          <div className="form-group col-md-5">
            <label>Dealer Type</label>
            <select
              name="dealerType"
              className="form-control dealerType"
              value={this.state.dealerType ? this.state.dealerType : ""}
              onChange={e => this.handleInputChange(e)}
              disabled={
                this.state.formAction == constants.FORM_ACTION_UPDATE
                  ? true
                  : false
              }
              required
            >
              <option value="">--Select--</option>
              <option
                key={dealerTypeConstants.DEALER_TYPE_GS_CAR}
                value={dealerTypeConstants.DEALER_TYPE_GS_CAR}
              >
                Guaranteed Sales Car
              </option>
              <option
                key={dealerTypeConstants.DEALER_TYPE_C2C_DEALER_CAR}
                value={dealerTypeConstants.DEALER_TYPE_C2C_DEALER_CAR}
              >
                Business To Customer
              </option>
            </select>
          </div>

          <div className="form-group col-md-5">
            <label>Address Line 1</label>
            <textarea
              className="form-control"
              type="text"
              cols="50"
              rows="3"
              name="addressLine1"
              value={this.state.addressLine1 ? this.state.addressLine1 : ""}
              onChange={e => this.handleInputChange(e)}
              required
            />
          </div>

          <div className="form-group col-md-5">
            <label>Address Line 2</label>
            <textarea
              className="form-control"
              type="text"
              cols="50"
              rows="3"
              name="addressLine2"
              value={this.state.addressLine2 ? this.state.addressLine2 : ""}
              onChange={e => this.handleInputChange(e)}
            />
          </div>
          <div className="form-group col-md-5">
            <label>State</label>
            <select
              className="form-control"
              name="stateId"
              id="stateId"
              onChange={e => this.handleInputChange(e)}
              required
              value={this.state.stateId}
            >
              <option value="">--Select State--</option>
              {statesList}
            </select>
          </div>

          <div className="form-group col-md-5">
            <label>City</label>
            <select
              className="form-control"
              name="cityId"
              id="cityId"
              onChange={e => this.handleInputChange(e)}
              required
              value={this.state.cityId}
            >
              <option value="">--Select City--</option>
              {citiesList}
            </select>
          </div>

          <div className="clearfix"></div>

          <div className="form-group col-md-5">
            <label>Is Active?</label>
            <input
              type="checkbox"
              name="status"
              checked={this.state.status}
              onChange={e => this.handleInputChange(e)}
            ></input>
          </div>
          <div className="clearfix"></div>

          {/* Markup slab section start here */}
          <div className="col-md-12">
            <h3>Mark Up Slabs</h3>
          </div>

          <div className="col-md-12">{slabRangeList}</div>

          {/* Markup slab section ends here */}
        </div>

        <div className="form-group col-md-8">
          <div className="rows">&nbsp;</div>
          <div className="form-group col-md-5">
            <button type="submit" className="btn btn-primary">
              {this.state.formAction}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

Dealer.propTypes = {};
const mapStateToProps = ({ dealer: { detail } }) => {
  return {
    detail
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDealerDetail: dealerId => DealerActions.fetchDealerDetail(dealerId),
      addDealer: data => DealerActions.addDealer(data),
      fetchDealerForm: () => DealerActions.fetchDealerForm(),
      fetchStates: () => StateActions.fetchStates(),
      fetchCities: params => CityActions.fetchCities(params)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dealer);
