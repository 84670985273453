import React, { Component } from "react";
import { Exterior } from "./exterior";
import { Interior } from "./interior";
import { Engine } from "./engine";
import { Tyre } from "./tyre";
import { Others } from "./others";

class Inspection extends Component {
  render() {
    const { inspection } = this.props;
    return (
      <React.Fragment>
        <Exterior inspection={inspection} />
        <Interior inspection={inspection} />
        <Engine inspection={inspection} />
        <Tyre inspection={inspection} />
        <Others inspection={inspection} />
      </React.Fragment>
    );
  }
}

export default Inspection;
