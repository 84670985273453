import { createReducer } from "reduxsauce";
import Types from "../constants/actionTypes";

export const INITIAL_STATE = {
  detail: {},
  isLoading: false
};

export const uploadFileInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const uploadFileSuccess = (state = INITIAL_STATE, { data: detail }) => {
  return { ...state, detail, isLoading: false };
};

export const uploadFileFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const HANDLERS = {
  [Types.ACTION_FILE_UPLOAD_INIT]: uploadFileInit,
  [Types.ACTION_FILE_UPLOAD_SUCCESS]: uploadFileSuccess,
  [Types.ACTION_FILE_UPLOAD_FAIL]: uploadFileFail
};

export default createReducer(INITIAL_STATE, HANDLERS);
