import { createReducer } from "reduxsauce";
import Types from "../constants/actionTypes";

export const INITIAL_STATE = {
  detail: {},
  dealerList: {},
  isLoading: false
};

//set state for fetch dealer init
export const fetchDealerDetailInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

//set state for fetch dealer success
export const fetchDealerDetailSuccess = (
  state = INITIAL_STATE,
  { data: detail }
) => {
  return { ...state, detail, isLoading: false };
};

//set state for fetch dealer failed
export const fetchDealerDetailFail = (
  state = INITIAL_STATE,
  { data: detail }
) => {
  return { ...state, detail, isLoading: false };
};

//set state for addDealer init
export const addDealerlInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

//set state for addDealer success
export const addDealerSuccess = (state = INITIAL_STATE, { data: detail }) => {
  return { ...state, detail, isLoading: false };
};

//set state for addDealer failed
export const addDealerFail = (state = INITIAL_STATE, { data: detail }) => {
  return { ...state, detail, isLoading: false };
};

export const fetchDealerFormInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const fetchDealerFormSuccess = (
  state = INITIAL_STATE,
  { data: detail }
) => {
  return { ...state, detail, isLoading: false };
};

export const fetchDealerFormFail = (
  state = INITIAL_STATE,
  { data: detail }
) => {
  return { ...state, detail, isLoading: false };
};

//Get dealer list
export const fetchDealerListInit = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const fetchDealerListSuccess = (
  state = INITIAL_STATE,
  { data: dealerListRes }
) => {
  let dealerList = [];
  if (dealerListRes) {
    dealerList = dealerListRes.response;
  }

  return { ...state, dealerList, isLoading: false };
};

export const fetchDealerListFail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const HANDLERS = {
  [Types.ACTION_FETCH_DEALER_INIT]: fetchDealerDetailInit,
  [Types.ACTION_FETCH_DEALER_SUCCESS]: fetchDealerDetailSuccess,
  [Types.ACTION_FETCH_DEALER_FAIL]: fetchDealerDetailFail,
  [Types.ACTION_ADD_DEALER_INIT]: addDealerlInit,
  [Types.ACTION_ADD_DEALER_SUCCESS]: addDealerSuccess,
  [Types.ACTION_ADD_DEALER_FAIL]: addDealerFail,
  [Types.ACTION_FETCH_DEALER_FORM_INIT]: fetchDealerFormInit,
  [Types.ACTION_FETCH_DEALER_FORM_SUCCESS]: fetchDealerFormSuccess,
  [Types.ACTION_FETCH_DEALER_FORM_FAIL]: fetchDealerFormFail,
  [Types.ACTION_FETCH_DEALER_LIST_INIT]: fetchDealerListInit,
  [Types.ACTION_FETCH_DEALER_LIST_SUCCESS]: fetchDealerListSuccess,
  [Types.ACTION_FETCH_DEALER_LIST_FAIL]: fetchDealerListFail
};

export default createReducer(INITIAL_STATE, HANDLERS);
