import Types from "../constants/actionTypes";
import { agentApi } from "../service/api";

const fetchAgentDetailInit = () => ({
  type: Types.ACTION_FETCH_AGENT_DETAIL_INIT
});

const fetchAgentDetailSuccess = data => ({
  type: Types.ACTION_FETCH_AGENT_DETAIL_SUCCESS,
  data
});

const fetchAgentDetailFail = error => ({
  type: Types.ACTION_FETCH_AGENT_DETAILS_FAIL,
  error
});

const fetchAgentDetail = id => dispatch => {
  dispatch(fetchAgentDetailInit());
  return new Promise((resolve, reject) => {
    agentApi
      .getAgentById(id)
      .then(resp => {
        resolve(resp);
        dispatch(fetchAgentDetailSuccess(resp));
      })
      .catch(error => {
        dispatch(fetchAgentDetailFail(error));
        reject(error);
      });
  });
};

const addAgentInit = () => ({
  type: Types.ACTION_ADD_AGENT_INIT
});

const addAgentSuccess = data => ({
  type: Types.ACTION_ADD_AGENT_SUCCESS,
  data
});

const addAgentFail = error => ({
  type: Types.ACTION_ADD_AGENT_FAIL,
  error
});

const addAgent = (data, params) => dispatch => {
  dispatch(addAgentInit());
  return new Promise((resolve, reject) => {
    agentApi
      .addAgent(data, params)
      .then(resp => {
        dispatch(addAgentSuccess(resp));
        resolve(resp);
      })
      .catch(error => {
        dispatch(addAgentFail(error));
        reject(error);
      });
  });
};

const updateAgentStatusInit = () => ({
  type: Types.ACTION_AGENT_STATUS_UPDATE_INIT
});

const updateAgentStatusSuccess = data => ({
  type: Types.ACTION_AGENT_STATUS_UPDATE_SUCCESS,
  data
});

const updateAgentStatusFail = error => ({
  type: Types.ACTION_AGENT_STATUS_UPDATE_FAIL,
  error
});

const updateAgentStatus = (data, params) => dispatch => {
  dispatch(updateAgentStatusInit());
  return new Promise((resolve, reject) => {
    agentApi
      .updateAgentStatus(data, params)
      .then(resp => {
        resolve(resp);
        dispatch(updateAgentStatusSuccess(resp));
      })
      .catch(error => {
        dispatch(updateAgentStatusFail(error));
        reject(error);
      });
  });
};

export { fetchAgentDetail, addAgent, updateAgentStatus };
