import { createReducer } from "reduxsauce";
import Types from "../constants/actionTypes";

export const INITIAL_STATE = {
  sidebar: true,
  profile: false
};

export const toggleSidebar = (state = INITIAL_STATE) => {
  state.sidebar
    ? document.body.classList.add("sidebar-collapse")
    : document.body.classList.remove("sidebar-collapse");

  return { ...state, sidebar: !state.sidebar };
};

export const toggleProfile = (state = INITIAL_STATE) => {
  return { ...state, profile: !state.profile };
};

export const HANDLERS = {
  [Types.ACTION_TOGGLE_SIDEBAR]: toggleSidebar,
  [Types.ACTION_TOGGLE_PROFILE]: toggleProfile
};

export default createReducer(INITIAL_STATE, HANDLERS);
