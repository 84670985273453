import React, { Component } from "react";

class Paginator extends Component {
  state = {
    size: 10,
    page: 0,
    totalPages: 0,
    totalRecords: 0,
    recordsOnPage: 0
  };

  //component will receive props
  componentWillReceiveProps({ page, totalPages, totalRecords, recordsOnPage }) {
    this.setState({
      page: page,
      totalPages: totalPages,
      totalRecords: totalRecords,
      recordsOnPage: recordsOnPage
    });
  }

  //Navigate to given page
  onClickPagination = pageNo => {
    this.props.onClickPagination(pageNo);
  };

  //Goto first page
  onBtFirst = () => {
    console.log("onBtFirst");
    this.onClickPagination(0);
  };

  //Goto last page
  onBtLast = () => {
    console.log("onBtLast");
    const totalRecords = parseInt(this.state.totalRecords);
    const lastPage = Math.ceil(totalRecords / this.state.size) - 1;
    this.onClickPagination(lastPage);
  };

  //Goto Next page
  onBtNext = () => {
    console.log("onBtNext");
    let nextPage = parseInt(this.state.page) + 1;
    const totalPages = parseInt(this.state.totalPages);
    if (nextPage === totalPages) {
      return false;
    }

    if (nextPage >= totalPages) {
      nextPage = totalPages;
    }
    this.onClickPagination(nextPage);
  };

  //Goto previous page
  onBtPrevious = () => {
    console.log("onBtPrevious");
    let prevPage = parseInt(this.state.page) - 1;
    if (prevPage < 0) {
      return false;
    }
    this.onClickPagination(prevPage);
  };

  render() {
    return (
      <div className="ag-grid-paginator-wrapper">
        <div>
          <button
            className="btn-sm btn-success ml8"
            onClick={() => this.onBtFirst()}
          >
            First
          </button>
          <button
            className="btn-sm btn-default ml8"
            onClick={() => this.onBtPrevious()}
          >
            Previous
          </button>
          <button
            className="btn-sm btn-default ml8"
            onClick={() => this.onBtNext()}
          >
            Next
          </button>
          <button
            className="btn-sm btn-success ml8"
            onClick={() => this.onBtLast()}
          >
            Last
          </button>
        </div>
        <div className="pull-right">
          <span className="ml8">
            showing {this.state.recordsOnPage} of {this.state.totalRecords}{" "}
            &nbsp;
          </span>
          |
          <span className="msl8">
            &nbsp;&nbsp;Page {this.state.page + 1} of {this.state.totalPages}
          </span>
        </div>
      </div>
    );
  }
}

export default Paginator;
