import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AgentActions } from "./../../actions";
import { If, Then } from "react-if";
import BasicDetail from "./basicDetail";
import KycDetail from "./kycDetail";
import { agentStatusConstants } from "../../constants/constants";
import { config } from "../../config/local";

class Agent extends Component {
  state = {
    selectedIndex: 0,
    agentId: "",
    agentStatus: ""
  };

  //handle on click
  handleOnClick = index => {
    this.setState({ selectedIndex: index });
  };

  //Load car details and seller info from API
  async componentDidMount() {
    const agentId = this.props.match.params.id;
    if (agentId) {
      await this.props.fetchAgentDetail(agentId);
    }
  }

  componentWillReceiveProps({ agentId, agentStatus }) {
    if (agentId) {
      this.setState({ agentId });
    }
    if (agentStatus) {
      this.setState({ agentStatus });
    }
  }

  updateAgentStatus = data => {
    this.props
      .updateAgentStatus(data)
      .then(() => this.props.history.push(config.api.c2c.agents.path));
  };

  //Render
  render() {
    const Tabs = () => {
      return (
        <ul className="nav nav-tabs">
          <li
            className={this.state.selectedIndex === 0 ? "active" : ""}
            onClick={() => this.handleOnClick(0)}
          >
            <a href="#basicDetail" data-toggle="tab" aria-expanded="true">
              <b>Basic Details</b>
            </a>
          </li>
          <li
            className={this.state.selectedIndex === 1 ? "active" : ""}
            onClick={() => this.handleOnClick(1)}
          >
            <a href="#kycDetail" data-toggle="tab" aria-expanded="false">
              <b>KYC Details</b>
            </a>
          </li>
          <If
            condition={
              this.state.agentStatus &&
              this.state.agentStatus !== agentStatusConstants.APPROVED &&
              this.state.agentStatus !== agentStatusConstants.REJECTED
            }
          >
            <Then>
              <div className="col-md-1 pull-right">
                <button
                  onClick={() =>
                    this.updateAgentStatus({
                      agentId: this.state.agentId,
                      action: "REJECT"
                    })
                  }
                  className="btn btn-danger"
                >
                  Reject
                </button>
              </div>
              <div className="col-md-1 pull-right">
                <button
                  onClick={() =>
                    this.updateAgentStatus({
                      agentId: this.state.agentId,
                      action: "APPROVE"
                    })
                  }
                  className="btn btn-success"
                >
                  Approve
                </button>
              </div>
            </Then>
          </If>
        </ul>
      );
    };

    return (
      <React.Fragment>
        <div className="col-md-12">
          <div className="nav-tabs-custom no-box-shadow ">
            <Tabs />
            <div className="tab-content">
              <div
                className={
                  this.state.selectedIndex === 0
                    ? "tab-pane active"
                    : "tab-pane"
                }
              >
                <BasicDetail />
              </div>
              <div
                className={
                  this.state.selectedIndex === 1
                    ? "tab-pane active"
                    : "tab-pane"
                }
              >
                <KycDetail />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  agent: {
    detail: { agentBasicDetails }
  }
}) => {
  const agentId = agentBasicDetails
    ? agentBasicDetails.agentId
      ? agentBasicDetails.agentId
      : ""
    : "";

  const agentStatus = agentBasicDetails
    ? agentBasicDetails.agentStatus
      ? agentBasicDetails.agentStatus
      : ""
    : "";

  return {
    agentId,
    agentStatus
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAgentDetail: agentId => AgentActions.fetchAgentDetail(agentId),
      updateAgentStatus: data => AgentActions.updateAgentStatus(data)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Agent);
