import React from "react";

export const Tyre = props => {
  const { exteriorTyres: tyres } = props.inspection;
  if (!tyres) {
    return "No data to show";
  }

  const { subparts } = tyres;
  if (!subparts) {
    return "No data to show";
  }

  const tyreArr = subparts.tyre;
  const tyreList = tyreArr.map(tyre => {
    return (
      <div key={Math.random(5)} className="form-group col-md-3">
        <label>{tyre.title}</label>
        <input
          type="text"
          className="form-control"
          value={tyre.value}
          readOnly={true}
        />
      </div>
    );
  });

  return (
    <React.Fragment>
      <div className="box-header">
        <h3 className="box-title">Tyre</h3>
      </div>
      <div className="box-body col-md-12">
        <div className="form-group col-md-3">
          <label>Alloy wheels</label>
          <input
            type="text"
            className="form-control"
            value={tyres ? tyres.alloyWheels : ""}
            readOnly={true}
          />
        </div>
        {tyreList}
      </div>
    </React.Fragment>
  );
};
