import * as httpService from "./httpService";
import { config } from "./../config/local";
import { httpConstants } from "../constants/httpConstants";
import { toast } from "react-toastify";

//Get dealers from dealerApi
export async function getDealers(params = {}, page = 0, size = 10) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.dealers.path;
  const url = host.concat(path);
  params.page = page;
  params.size = size;
  const response = await httpService.get(url, params);
  if (response && response.status === httpConstants.HTTP_STATUS_OK) {
    return response.data ? response.data : [];
  } else {
    return [];
  }
}

//Get c2c dealers from dealerApi
export async function getC2cDealers(params = {}) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.dealers.path;
  let q = "?dealerType=C2C_DEALER_CAR&data=all&status=1";
  if (params.cityId) {
    q = `${q}&cityId=${params.cityId}`;
  }
  const url = host.concat(path).concat(q);
  const response = await httpService.get(url);
  if (response && response.status === httpConstants.HTTP_STATUS_OK) {
    return response.data ? response.data : [];
  } else {
    return [];
  }
}
//Get dealer details by id
export async function getDealerDetailById(dealerId) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.dealers.path;
  const url = host
    .concat(path)
    .concat("/")
    .concat(dealerId);
  const response = await httpService.get(url);
  if (response && response.status === httpConstants.HTTP_STATUS_OK) {
    return response.data ? response.data : [];
  } else {
    return [];
  }
}

//Add dealer
export async function addDealer(data) {
  const host = config.api.c2c.host;
  const path = config.api.c2c.dealers.path;
  const url = host.concat(path);
  const response = await httpService.post(url, data);
  if (response && response.status === httpConstants.HTTP_STATUS_OK) {
    toast.success("Dealer has been updated successfully");
    return response.data ? response.data : [];
  } else {
    return [];
  }
}

//fetch intial form data for dealerForm
export async function getDealerFormData() {
  const host = config.api.c2c.host;
  const path = config.api.c2c.dealerForm.path;
  const url = host.concat(path);
  const response = await httpService.get(url);
  if (response && response.status === httpConstants.HTTP_STATUS_OK) {
    return response.data ? response.data : [];
  } else {
    return [];
  }
}
