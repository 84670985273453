import Types from "../constants/actionTypes";
import { dealerApi } from "../service/api";

//init dealerApi
const fetchDealerDetailInit = () => {
  return {
    type: Types.ACTION_FETCH_DEALER_INIT
  };
};

//dealerDetails fetch success
const fetchDealerDetailSuccess = data => {
  return {
    type: Types.ACTION_FETCH_DEALER_SUCCESS,
    data: data
  };
};

//dealerDetails fetch error
const fetchDealerDetailFail = error => {
  return {
    type: Types.ACTION_FETCH_DEALER_FAIL,
    error: error
  };
};

//fetch dealerDetaisl
const fetchDealerDetail = dealerId => dispatch => {
  dispatch(fetchDealerDetailInit());
  return new Promise((resolve, reject) => {
    dealerApi
      .getDealerDetailById(dealerId)
      .then(resp => {
        resolve(resp);
        dispatch(fetchDealerDetailSuccess(resp));
      })
      .catch(error => {
        dispatch(fetchDealerDetailFail(error));
        reject(error);
      });
  });
};

//init add dealer
const addDealerInit = () => {
  return {
    type: Types.ACTION_ADD_DEALER_INIT
  };
};

//addDealer success
const addDealerSuccess = data => {
  return {
    type: Types.ACTION_ADD_DEALER_SUCCESS,
    data: data
  };
};

//addDealer error
const addDealerFail = error => {
  return {
    type: Types.ACTION_ADD_DEALER_FAIL,
    error: error
  };
};

const addDealer = data => dispatch => {
  dispatch(addDealerInit());
  return dealerApi
    .addDealer(data)
    .then(resp => {
      dispatch(addDealerSuccess(resp));
      return resp.data;
    })
    .catch(error => {
      dispatch(addDealerFail(error));
    });
};

const fetchDealerFormInit = () => {
  return {
    type: Types.ACTION_FETCH_DEALER_FORM_INIT
  };
};

const fetchDealerFormSuccess = data => {
  return {
    type: Types.ACTION_FETCH_DEALER_FORM_SUCCESS,
    data: data
  };
};

const fetchDealerFormFail = error => {
  return {
    type: Types.ACTION_FETCH_DEALER_FORM_FAIL,
    error: error
  };
};

const fetchDealerForm = data => dispatch => {
  dispatch(fetchDealerFormInit());
  return dealerApi
    .getDealerFormData(data)
    .then(resp => {
      dispatch(fetchDealerFormSuccess(resp));
      return resp.data;
    })
    .catch(error => {
      dispatch(fetchDealerFormFail(error));
    });
};

//Get dealer list
const fetchDealerListInit = () => {
  return {
    type: Types.ACTION_FETCH_DEALER_LIST_INIT
  };
};

const fetchDealerListSuccess = data => {
  return {
    type: Types.ACTION_FETCH_DEALER_LIST_SUCCESS,
    data: data
  };
};

const fetchDealerListFail = error => {
  return {
    type: Types.ACTION_FETCH_DEALER_LIST_FAIL,
    error: error
  };
};

const fetchC2cDealerList = params => dispatch => {
  dispatch(fetchDealerListInit());
  return dealerApi
    .getC2cDealers(params)
    .then(resp => {
      dispatch(fetchDealerListSuccess(resp));
      return resp.data;
    })
    .catch(error => {
      dispatch(fetchDealerListFail(error));
    });
};

export { fetchDealerDetail, addDealer, fetchDealerForm, fetchC2cDealerList };
