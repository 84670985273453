import React, { Component } from "react";

class LabelRenderer extends Component {
  //Filter space and underscore from given string
  filterValue = str => {
    return str.replace(/[_-]/g, " ");
  };

  //Convert string to title case
  titleCase = str => {
    if (str && str.length > 0) {
      let filteredValue = this.filterValue(str);
      const titleCase = filteredValue
        .toLowerCase()
        .split(" ")
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
      return titleCase;
    }
    return "";
  };

  render() {
    let cellValue = this.props.value;
    let options = this.props.options;
    if (options) {
      options.forEach(option => {
        if (option.key !== "undefined" && option.key == cellValue) {
          cellValue = option.value;
          return true;
        }
      });
    }
    return <span>{cellValue}</span>;
  }
}

export default LabelRenderer;
