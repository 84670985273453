import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CommonActions, UserActions } from "./actions";
import AppRouter from "./components/common/appRouter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import Header from "./components/common/header";
import Sidebar from "./components/common/sidebar";
import Loader from "./components/common/loader";
import Footer from "./components/common/footer";
import Login from "./components/login/login";
import * as LoginHelper from "./helper/loginHelper";
import ErrorBoundary from "./components/common/errorBoundary";

class App extends Component {
  //handle page Heading
  handleHeading = heading => {
    this.props.updateHeading(heading);
  };

  //update user details in store
  componentDidMount() {
    this.props.updateUser();
  }

  //Render main component
  render() {
    const user = LoginHelper.authenticate();
    //Verify whether user is logged-in
    if (!user) {
      return <Login />;
    }

    const {
      common: { heading }
    } = this.props;

    return (
      <Router>
        <Header />
        <Sidebar handleHeading={this.handleHeading} />
        <ErrorBoundary>
          <section className="content-wrapper main-content-wrapper overlay-wrapper">
            <Loader />
            <section className="content-header">
              <h1>{heading}</h1>
            </section>
            <AppRouter />
            <ToastContainer />
          </section>
        </ErrorBoundary>
        <Footer />
        <div className="control-sidebar-bg" />
      </Router>
    );
  }
}

App.propTypes = {};
const mapStateToProps = ({ common, user }) => {
  return {
    common,
    user
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateHeading: CommonActions.updateHeading,
      updateUser: UserActions.setUserDetails
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
