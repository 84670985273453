import Types from "../constants/actionTypes";
import { orderApi } from "./../service/api";

//Fetch order details
const fetchOrderDetailInit = () => ({
  type: Types.ACTION_FETCH_ORDER_DETAIL_INIT
});

const fetchOrderDetailSuccess = data => ({
  type: Types.ACTION_FETCH_ORDER_DETAIL_SUCCESS,
  data
});

const fetchOrderDetailFail = error => ({
  type: Types.ACTION_FETCH_ORDER_DETAIL_FAIL,
  error
});

const fetchOrderDetail = appointmentId => dispatch => {
  dispatch(fetchOrderDetailInit());
  return new Promise((resolve, reject) => {
    orderApi
      .getOrderPageDetails(appointmentId)
      .then(resp => {
        resolve(resp);
        dispatch(fetchOrderDetailSuccess(resp));
      })
      .catch(error => {
        dispatch(fetchOrderDetailFail(error));
        reject(error);
      });
  });
};

//update order state
const updateOrderStateInit = () => ({
  type: Types.ACTION_UPDATE_ORDER_STATE_INIT
});

const updateOrderStateSuccess = data => ({
  type: Types.ACTION_UPDATE_ORDER_STATE_SUCCESS,
  data
});

const updateOrderStateFail = error => ({
  type: Types.ACTION_UPDATE_ORDER_STATE_FAIL,
  error
});

const updateOrderState = data => dispatch => {
  dispatch(updateOrderStateInit());
  return new Promise((resolve, reject) => {
    orderApi
      .updateOrderState(data)
      .then(resp => {
        resolve(resp);
        dispatch(updateOrderStateSuccess(resp));
      })
      .catch(error => {
        dispatch(updateOrderStateFail(error));
        reject(error);
      });
  });
};

//update order detail
const updateOrderInit = () => ({
  type: Types.ACTION_UPDATE_ORDER_INIT
});

const updateOrderSuccess = data => ({
  type: Types.ACTION_UPDATE_ORDER_SUCCESS,
  data
});

const updateOrderFail = error => ({
  type: Types.ACTION_UPDATE_ORDER_FAIL,
  error
});

const updateOrder = data => dispatch => {
  dispatch(updateOrderInit());
  return orderApi
    .updateOrder(data)
    .then(resp => {
      dispatch(updateOrderSuccess(resp));
      return resp.data;
    })
    .catch(error => {
      dispatch(updateOrderFail(error));
    });
};

//Fetch order inspection details
const fetchOrderInspectionDetailInit = () => ({
  type: Types.ACTION_FETCH_ORDER_INSPECTION_DETAIL_INIT
});

const fetchOrderInspectionDetailSuccess = data => ({
  type: Types.ACTION_FETCH_ORDER_INSPECTION_DETAIL_SUCCESS,
  data
});

const fetchOrderInspectionDetailFail = error => ({
  type: Types.ACTION_FETCH_ORDER_INSPECTION_DETAIL_FAIL,
  error
});

const fetchOrderInspectionDetail = appointmentId => dispatch => {
  dispatch(fetchOrderInspectionDetailInit());
  return new Promise((resolve, reject) => {
    orderApi
      .getOrderInspectionDetails(appointmentId)
      .then(resp => {
        resolve(resp);
        dispatch(fetchOrderInspectionDetailSuccess(resp));
      })
      .catch(error => {
        dispatch(fetchOrderInspectionDetailFail(error));
        reject(error);
      });
  });
};

const updateCarSource = data => () => {
  return new Promise((resolve, reject) => {
    orderApi
      .updateOnboardInventory(data)
      .then(resp => {
        resolve(resp);
      })
      .catch(error => {
        reject(error);
      });
  });
};

//Add B2C order
const addB2cOrderInit = () => ({
  type: Types.ACTION_ADD_B2C_ORDER_INIT
});

const addB2cOrderSuccess = data => ({
  type: Types.ACTION_ADD_B2C_ORDER_SUCCESS,
  data
});

const addB2cOrderFail = error => ({
  type: Types.ACTION_ADD_B2C_ORDER_FAIL,
  error
});

const addB2cOrder = data => dispatch => {
  dispatch(addB2cOrderInit());
  return orderApi
    .addB2cOrder(data)
    .then(resp => {
      dispatch(addB2cOrderSuccess(resp));
      return resp;
    })
    .catch(error => {
      dispatch(addB2cOrderFail(error));
      return error;
    });
};

//Fetch C2b order details
const fetchC2bOrderDetailInit = () => ({
  type: Types.ACTION_FETCH_C2B_ORDER_DETAIL_INIT
});

const fetchC2bOrderDetailSuccess = data => ({
  type: Types.ACTION_FETCH_C2B_ORDER_DETAIL_SUCCESS,
  data
});

const fetchC2bOrderDetailFail = error => ({
  type: Types.ACTION_FETCH_C2B_ORDER_DETAIL_FAIL,
  error
});

const fetchC2bOrderDetail = appointmentId => dispatch => {
  dispatch(fetchC2bOrderDetailInit());
  return new Promise((resolve, reject) => {
    orderApi
      .getC2bOrderDetails(appointmentId)
      .then(resp => {
        resolve(resp);
        dispatch(fetchC2bOrderDetailSuccess(resp));
      })
      .catch(error => {
        dispatch(fetchC2bOrderDetailFail(error));
        reject(error);
      });
  });
};

const resetC2bOrderFields = () => dispatch => {
  dispatch({
    type: Types.ACTION_RESER_C2B_ORDER_FIELDS
  });
};

//Fetch seller inspection details
const fetchSellerInspectionDetailInit = () => ({
  type: Types.ACTION_FETCH_SELLER_INSPECTION_DETAIL_INIT
});

const fetchSellerInspectionDetailSuccess = data => ({
  type: Types.ACTION_FETCH_SELLER_INSPECTION_DETAIL_SUCCESS,
  data
});

const fetchSellerInspectionDetailFail = error => ({
  type: Types.ACTION_FETCH_SELLER_INSPECTION_DETAIL_FAIL,
  error
});

const fetchSellerInspectionDetail = appointmentId => dispatch => {
  dispatch(fetchSellerInspectionDetailInit());
  return new Promise((resolve, reject) => {
    orderApi
      .getSellerInspectionDetailByAppointmentId(appointmentId)
      .then(resp => {
        resolve(resp);
        dispatch(fetchSellerInspectionDetailSuccess(resp));
      })
      .catch(error => {
        dispatch(fetchSellerInspectionDetailFail(error));
        reject(error);
      });
  });
};

//export all usable actions
export {
  fetchOrderDetail,
  updateOrderState,
  updateOrder,
  fetchOrderInspectionDetail,
  updateCarSource,
  addB2cOrder,
  fetchC2bOrderDetail,
  resetC2bOrderFields,
  fetchSellerInspectionDetail
};
