import Types from "../constants/actionTypes";
import { storeApi } from "./../service/api";

//Fetch store details
const fetchStoreDetailInit = () => ({
  type: Types.ACTION_FETCH_STORE_INIT
});

const fetchStoreDetailSuccess = data => ({
  type: Types.ACTION_FETCH_STORE_SUCCESS,
  data
});

const fetchStoreDetailFail = error => ({
  type: Types.ACTION_FETCH_STORE_FAIL,
  error
});

const fetchStoreDetail = storeId => dispatch => {
  dispatch(fetchStoreDetailInit());
  return new Promise((resolve, reject) => {
    storeApi
      .getStoreDetails(storeId)
      .then(resp => {
        resolve(resp);
        dispatch(fetchStoreDetailSuccess(resp));
      })
      .catch(error => {
        dispatch(fetchStoreDetailFail(error));
        reject(error);
      });
  });
};

const addStoreInit = () => ({
  type: Types.ACTION_ADD_STORE_INIT
});

const addStoreSuccess = data => ({
  type: Types.ACTION_ADD_STORE_SUCCESS,
  data
});

const addStoreFail = error => ({
  type: Types.ACTION_ADD_STORE_FAIL,
  error
});

const addStore = data => dispatch => {
  dispatch(addStoreInit());
  return new Promise((resolve, reject) => {
    storeApi
      .addStore(data)
      .then(resp => {
        resolve(resp);
        dispatch(addStoreSuccess(resp));
      })
      .catch(error => {
        dispatch(addStoreFail(error));
        reject(error);
      });
  });
};

const fetchAllStoresInit = () => ({
  type: Types.ACTION_FETCH_ALL_STORES_INIT
});

const fetchAllStoresSuccess = data => ({
  type: Types.ACTION_FETCH_ALL_STORES_SUCCESS,
  data
});

const fetchAllStoresFail = error => ({
  type: Types.ACTION_FETCH_ALL_STORES_FAIL,
  error
});

const fetchAllStores = data => dispatch => {
  dispatch(fetchAllStoresInit());
  return new Promise((resolve, reject) => {
    storeApi
      .getStores(data)
      .then(resp => {
        resolve(resp);
        dispatch(fetchAllStoresSuccess(resp));
      })
      .catch(error => {
        dispatch(fetchAllStoresFail(error));
        reject(error);
      });
  });
};

//export all usable actions
export { fetchStoreDetail, addStore, fetchAllStores };
