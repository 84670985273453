import Types from "../constants/actionTypes";

const updateHeading = data => dispatch => {
  let action = {
    type: Types.ACTION_UPDATE_HEADING,
    data
  };
  dispatch(action);
};

export { updateHeading };
