import Types from "../constants/actionTypes";
import * as LoginHelper from "./../helper/loginHelper";

const userLoginSuccess = data => {
  return {
    type: Types.userLoginSuccess,
    data
  };
};

//Get user details
const setUserDetails = () => dispatch => {
  const user = LoginHelper.getUserDetails();
  const action = {
    type: Types.ACTION_SET_USER,
    user
  };
  dispatch(action);
};

//Logout user from memory
const logout = () => dispatch => {
  LoginHelper.logout();
  LoginHelper.authenticate();
  const action = {
    type: Types.ACTION_LOGOUT
  };
  dispatch(action);
};

export { userLoginSuccess, logout, setUserDetails };
