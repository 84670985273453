import React from "react";
import { connect } from "react-redux";

const Loader = order => {
  const isLoading = order && order.order ? order.order.isLoading : false;
  return (
    <div className={isLoading ? "this-page-loader" : "this-page-loader hide"}>
      <div className="flex-loader">
        <i className="fa fa-refresh fa-spin" />
      </div>
    </div>
  );
};

Loader.propTypes = {};
const mapStateToProps = ({ order }) => {
  return {
    order
  };
};

export default connect(mapStateToProps)(Loader);
