import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { orderApi } from "./../../service/api";
import * as gridHelper from "./../../helper/mainGridHelper";
import LinkRenderer from "../common/grid/linkRenderer";
import LabelRenderer from "../common/grid/labelRenderer";
import DropDwonFilter from "../common/grid/dropDwonFilter";
import {
  carSourceConstants,
  backgroundCheckStatusConstants,
  orderStateConstants,
  inventoryStatusConstants
} from "./../../constants/constants";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Paginator from "../common/grid/paginator";
import { Link } from "react-router-dom";

class MainGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      totalPages: 0,
      recordsOnPage: 0,
      totalRecords: 0,
      filterApplied: false,
      floatingFilter: false,
      defaultColDef: {
        // sortable: true,
        // filter: true
      },
      columnDefs: [
        {
          headerName: "Appt. ID",
          width: 160,
          field: "appointmentId",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            // debounceMs: 0,
            caseSensitive: true,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          },
          cellRenderer: "LinkRenderer",
          floatingFilterComponentParams: { suppressFilterButton: true }
        },
        {
          headerName: "Reg. No",
          width: 160,
          field: "registrationNumber",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            caseSensitive: false,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          },
          floatingFilterComponentParams: { suppressFilterButton: true }
        },
        {
          headerName: "Make",
          width: 160,
          field: "make",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            caseSensitive: false,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          },
          floatingFilterComponentParams: { suppressFilterButton: true }
        },
        {
          headerName: "Model",
          width: 160,
          field: "model",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            caseSensitive: false,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          },
          floatingFilterComponentParams: { suppressFilterButton: true }
        },
        {
          headerName: "Inventory Status",
          width: 160,
          field: "inventoryStatus",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            caseSensitive: false,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          },
          floatingFilterComponent: "DropDwonFilter",
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            name: "inventoryStatus",
            options: [
              { key: inventoryStatusConstants.NEW_CAR, value: "New Car" },
              { key: inventoryStatusConstants.IN_TRANSIT, value: "In Transit" },
              { key: inventoryStatusConstants.STOCK_IN, value: "Stock In" },
              { key: inventoryStatusConstants.STOCK_OUT, value: "Stock Out" }
            ]
          },
          cellRenderer: "LabelRenderer",
          cellRendererParams: {
            options: [
              { key: inventoryStatusConstants.NEW_CAR, value: "New Car" },
              { key: inventoryStatusConstants.IN_TRANSIT, value: "In Transit" },
              { key: inventoryStatusConstants.STOCK_IN, value: "Stock In" },
              { key: inventoryStatusConstants.STOCK_OUT, value: "Stock Out" }
            ]
          },
          suppressMenu: true
        },
        {
          headerName: "Store",
          width: 160,
          field: "storeName",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            caseSensitive: false,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          },
          cellRenderer: "LabelRenderer",
          floatingFilterComponentParams: { suppressFilterButton: true }
        },
        {
          headerName: "BG Status",
          width: 160,
          field: "backgroundCheckStatus",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            caseSensitive: false,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          },
          floatingFilterComponent: "DropDwonFilter",
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            name: "backgroundCheckStatus",
            options: [
              { key: backgroundCheckStatusConstants.PENDING, value: "Pending" },
              { key: backgroundCheckStatusConstants.ASSURED, value: "Assured" },
              {
                key: backgroundCheckStatusConstants.NON_ASSURED,
                value: "Non Assured"
              },
              { key: backgroundCheckStatusConstants.DELIST, value: "Delist" }
            ]
          },
          cellRenderer: "LabelRenderer",
          cellRendererParams: {
            options: [
              { key: backgroundCheckStatusConstants.PENDING, value: "Pending" },
              { key: backgroundCheckStatusConstants.ASSURED, value: "Assured" },
              {
                key: backgroundCheckStatusConstants.NON_ASSURED,
                value: "Non Assured"
              },
              { key: backgroundCheckStatusConstants.DELIST, value: "Delist" }
            ]
          },
          suppressMenu: true
        },
        {
          headerName: "Order State",
          field: "orderState",
          width: 150,
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            caseSensitive: false,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          },
          floatingFilterComponent: "DropDwonFilter",
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            name: "orderState",
            options: [
              { key: orderStateConstants.DRAFT, value: "Draft" },
              { key: orderStateConstants.PUBLISH, value: "Publish" },
              { key: orderStateConstants.BOOKED, value: "Booked" },
              { key: orderStateConstants.SOLD, value: "Sold" },
              { key: orderStateConstants.ARCHIVE, value: "Archive" }
            ]
          },
          cellRenderer: "LabelRenderer",
          cellRendererParams: {
            options: [
              { key: orderStateConstants.DRAFT, value: "Draft" },
              { key: orderStateConstants.PUBLISH, value: "Publish" },
              { key: orderStateConstants.BOOKED, value: "Booked" },
              { key: orderStateConstants.SOLD, value: "Sold" },
              { key: orderStateConstants.ARCHIVE, value: "Archive" }
            ]
          },
          suppressMenu: true
        },
        {
          headerName: "Car Source",
          field: "carSource",
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            caseSensitive: false,
            suppressAndOrCondition: true,
            applyButton: true,
            clearButton: true
          },
          floatingFilterComponent: "DropDwonFilter",
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            name: "carSource",
            options: [
              {
                key: carSourceConstants.CUSTOMER_CARE,
                value: "Customer Care"
              },
              { key: carSourceConstants.GS_CAR, value: "Guaranteed Sales Car" },
              { key: carSourceConstants.MANUAL_UPLOAD, value: "Manual Upload" },
              {
                key: carSourceConstants.C2C_DEALER_CAR,
                value: "Business To Customer"
              }
            ]
          },
          cellRenderer: "LabelRenderer",
          cellRendererParams: {
            options: [
              {
                key: carSourceConstants.CUSTOMER_CARE,
                value: "Customer Care"
              },
              { key: carSourceConstants.GS_CAR, value: "Guaranteed Sales Car" },
              { key: carSourceConstants.MANUAL_UPLOAD, value: "Manual Upload" },
              {
                key: carSourceConstants.C2C_DEALER_CAR,
                value: "Business To Customer"
              }
            ]
          },
          suppressMenu: true
        }
      ],
      context: { componentParent: this },
      frameworkComponents: {
        LinkRenderer: LinkRenderer,
        LabelRenderer: LabelRenderer,
        DropDwonFilter: DropDwonFilter
      }
    };
  }

  render() {
    return (
      <React-Fragement>
        <div>
          <div className="row">&nbsp;</div>
          {/* <button onClick={() => this.clearFilters()} className="btn bg-navy">
            Clear Filters
          </button> */}
          <div className="row">
            <div className="col-md-1 pull-right">
              <Link to="/orders/add">
                <button className="btn btn-success" type="button">
                  Add
                </button>
              </Link>
            </div>
            <br />
          </div>
          <div className="ag-theme-balham ag-grid-outer-wrapper">
            <AgGridReact
              columnDefs={this.state.columnDefs}
              rowData={this.state.rowData}
              defaultColDef={this.state.defaultColDef}
              context={this.state.context}
              // pagination
              paginationPageSize={10}
              floatingFilter
              onFilterChanged={this.handleFilterChanged}
              frameworkComponents={this.state.frameworkComponents}
              onGridReady={this.onGridReady}
              //enableSorting
              // onPaginationChanged={this.handlePaginationChanged}
              // onSortChanged={this.handleSortChanged}
              // suppressPaginationPanel
              enableCellTextSelection={true}
            />
            <Paginator
              page={this.state.page}
              totalPages={this.state.totalPages}
              totalRecords={this.state.totalRecords}
              recordsOnPage={this.state.recordsOnPage}
              onClickPagination={this.navigatePagination}
            />
          </div>
        </div>
      </React-Fragement>
    );
  }

  //Load orders on component mount
  async componentDidMount() {
    const {
      response: orders,
      page,
      totalPages,
      totalRecords
    } = await orderApi.getOrders();
    this.setState({
      rowData: orders,
      page: page,
      totalPages: totalPages,
      totalRecords: totalRecords,
      recordsOnPage: orders ? orders.length : 0
    });
  }

  //Clear all filters
  clearFilters = () => {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  };

  //Redirect to stock detail page
  redirectToDetailsPage(appointmentId) {
    this.props.history.push("/order/stock/" + appointmentId);
  }

  //Get name of unique identifier for each row
  getUniqueIdName() {
    return "appointmentId";
  }

  //Set grid and columns
  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  //Handle grid filters
  handleFilterChanged = () => {
    this.resetGridWithNewData();
  };

  //Navigate pagination
  navigatePagination = async pageNo => {
    const model = { ...this.gridApi.getFilterModel() };
    const query = gridHelper.createFilterQuery(model);
    const {
      response: orders,
      page,
      totalPages,
      totalRecords
    } = await orderApi.getOrders(query, pageNo);
    this.setState({
      rowData: orders,
      page: page,
      totalPages: totalPages,
      totalRecords: totalRecords,
      recordsOnPage: orders ? orders.length : 0
    });
    if (query) {
      this.gridApi.setFilterModel(model);
    }
    this.setState({ filterApplied: true });
  };

  //Reset grid with newly fetched data
  resetGridWithNewData = async pageNo => {
    if (!this.state.filterApplied) {
      const model = { ...this.gridApi.getFilterModel() };
      const query = gridHelper.createFilterQuery(model);
      const {
        response: orders,
        page,
        totalPages,
        totalRecords
      } = await orderApi.getOrders(query, pageNo);
      this.setState({
        rowData: orders,
        page: page,
        totalPages: totalPages,
        totalRecords: totalRecords,
        recordsOnPage: orders ? orders.length : 0
      });
      if (query) {
        this.gridApi.setFilterModel(model);
      }
      this.setState({ filterApplied: true });
    } else {
      this.setState({ filterApplied: false });
    }
  };
}

export default MainGrid;
