import { constants } from "./../constants/constants";
import { config } from "./../config/local";

//Redirect after login
export function redirectToLoginServer() {
  const c2c = window.location.href;
  const oms = config.api.oms.host;
  const path = config.api.oms.authentication.path;
  const client = constants.LOGIN_REQUEST_CLIENT;
  const auth = constants.LOGIN_REQUEST_TOKEN_TYPE;
  const url = oms
    .concat(path)
    .concat("?client=")
    .concat(client)
    .concat("&auth=")
    .concat(auth)
    .concat("&redirect_to=")
    .concat(c2c);
  window.location.href = url;
}

//Get user details
export function getUserDetails() {
  const user = localStorage.getItem("c2cUser");
  return JSON.parse(user);
}

//Authenticate user from local storage
export function authenticate() {
  let c2cUser = localStorage.getItem("c2cUser");
  let user = "";
  if (c2cUser) {
    const c2cUserObj = JSON.parse(c2cUser);
    const currentEnv = getHostName();
    if (c2cUserObj.env === currentEnv) {
      user = c2cUser;
    }
  }

  if (!user) {
    user = validateAfterLoginUrl();
  }

  return JSON.parse(user);
}

//validate and login user by reading URL
function validateAfterLoginUrl() {
  const client = getQueryParam("client");
  const auth = getQueryParam("auth");
  const key = getQueryParam("key");
  const email = getQueryParam("user");
  let isNewLogin =
    client === constants.LOGIN_REQUEST_CLIENT &&
    auth === constants.LOGIN_REQUEST_TOKEN_TYPE &&
    key &&
    email;
  //Set user in storage when new login occurs
  if (isNewLogin) {
    const env = getHostName();
    const user = JSON.stringify({ env: env, email: email, token: key });
    localStorage.setItem("c2cUser", user);
    const url = window.location.href;
    const path = getPathFromUrl(url);
    window.location.href = path;
  }

  return null;
}

function getPathFromUrl(url) {
  return url.split("?")[0];
}
//Logout user and destroy c2cUser object from memory
export function logout() {
  localStorage.removeItem("c2cUser");
}

//Get query params
export function getQueryParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

//Get application host name
export function getHostName() {
  const protocol = window.location.protocol;
  const slashes = protocol.concat("//");
  return slashes.concat(window.location.hostname);
}
