import {
  legalStatusConstants,
  agentStatusConstants,
  inventoryStatusConstants,
  stockOutReasonsConstants
} from "../constants/constants";

export const getLegalStatus = () => {
  return {
    [legalStatusConstants.INDIVIDUAL]: "Individual",
    [legalStatusConstants.COMPANY]: "Company",
    [legalStatusConstants.PARTNERSHIP]: "Partnership",
    [legalStatusConstants.PROPRIETORSHIP]: "Propreitorship",
    [legalStatusConstants.HUF]: "Huf",
    [legalStatusConstants.OTHERS]: "Others"
  };
};

export const getAgentStatus = () => {
  return {
    [agentStatusConstants.PENDING]: "Pending",
    [agentStatusConstants.ASSIGNED_TO_FINANCE]: "Assigned To Finance",
    [agentStatusConstants.ASSIGNED_TO_VENDOR]: "Assigned To Vendor",
    [agentStatusConstants.APPROVED]: "Approved",
    [agentStatusConstants.REJECTED]: "Rejected"
  };
};

export const getInventoryStatus = () => {
  return {
    [inventoryStatusConstants.IN_TRANSIT]: "In-Transit",
    [inventoryStatusConstants.STOCK_IN]: "Stock In",
    [inventoryStatusConstants.STOCK_OUT]: "Stock Out",
    [inventoryStatusConstants.NEW_CAR]: "New Car"
  };
};

export const getStockOutReasons = () => {
  return {
    [stockOutReasonsConstants.C2C_STORE_MOVEMENT]: "C2C Store Movement",
    [stockOutReasonsConstants.SOLD]: "Sold",
    [stockOutReasonsConstants.OUT_FOR_MAINTENANCE]: "Out For Maintenance"
  };
};
