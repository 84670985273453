import Types from "../constants/actionTypes";
import { inventoryApi } from "../service/api";

const updateInventoryInit = () => ({
  type: Types.ACTION_INVENTORY_UPDATE_INIT
});

const updateInventorySuccess = data => ({
  type: Types.ACTION_INVENTORY_UPDATE_SUCCESS,
  data
});

const updateInventoryFail = error => ({
  type: Types.ACTION_INVENTORY_UPDATE_FAIL,
  error
});

const updateInventory = (data, params) => dispatch => {
  dispatch(updateInventoryInit());
  return new Promise((resolve, reject) => {
    inventoryApi
      .updateInventory(data, params)
      .then(resp => {
        resolve(resp);
        dispatch(updateInventorySuccess(resp));
      })
      .catch(error => {
        dispatch(updateInventoryFail(error));
        reject(error);
      });
  });
};

const getInventoryLogInit = () => ({
  type: Types.ACTION_INVENTORY_LOG_INIT
});

const getInventoryLogSuccess = data => ({
  type: Types.ACTION_INVENTORY_LOG_SUCCESS,
  data
});

const getInventoryLogFail = error => ({
  type: Types.ACTION_INVENTORY_LOG_FAIL,
  error
});

const getInventoryLog = appointmentId => dispatch => {
  dispatch(getInventoryLogInit());
  return new Promise((resolve, reject) => {
    inventoryApi
      .getInventoryLog(appointmentId)
      .then(resp => {
        resolve(resp);
        dispatch(getInventoryLogSuccess(resp));
      })
      .catch(error => {
        dispatch(getInventoryLogFail(error));
        reject(error);
      });
  });
};

export { updateInventory, getInventoryLog };
