//Check is file image
export const isFileImage = file => {
  if (!file) {
    return false;
  }
  const mimeType = file["type"];
  return mimeType.split("/")[0] === "image" ? true : false;
};

//Check is file pdf
export const isFilePdf = file => {
  if (!file) {
    return false;
  }
  const mimeType = file["type"];
  return mimeType.split("/")[1] === "pdf" ? true : false;
};

//Convert Uppercase string to title case
export const titleCase = str => {
  if (str && str.length > 0) {
    let filteredValue = str.replace(/[_-]/g, " ");
    const titleCase = filteredValue
      .toLowerCase()
      .split(" ")
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    return titleCase;
  }
  return "";
};

export const makeFieldsReadonly = ignoredFields => {
  let inputs = document.getElementsByTagName("input");
  for (let i = 0; i < inputs.length; i++) {
    console.log(inputs[i].setAttribute("readOnly", true));
  }
};
