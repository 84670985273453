import React from "react";

export const Interior = props => {
  const { electricalsInterior: interior, airConditioning } = props.inspection;

  return (
    <React.Fragment>
      <div className="box-header">
        <h3 className="box-title">Interior</h3>
      </div>
      <div className="box-body col-md-12">
        <div className="form-group col-md-3">
          <label>Power windows</label>
          <input
            type="text"
            className="form-control"
            value={interior ? interior.powerWindows : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Airbag feature</label>
          <input
            type="text"
            className="form-control"
            value={interior ? interior.airbagFeature : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Music System</label>
          <input
            type="text"
            className="form-control"
            value={interior ? interior.musicSystem : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Leather Seats</label>
          <input
            type="text"
            className="form-control"
            value={interior ? interior.leatherSeats : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Fabric Seats</label>
          <input
            type="text"
            className="form-control"
            value={interior ? interior.fabricSeats : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Sunroof</label>
          <input
            type="text"
            className="form-control"
            value={interior ? interior.sunroof : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Steering Mounted Audio</label>
          <input
            type="text"
            className="form-control"
            value={interior ? interior.steeringMountedAudioControls : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>ABS</label>
          <input
            type="text"
            className="form-control"
            value={interior ? interior.abs : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>AC Cooling</label>
          <input
            type="text"
            className="form-control"
            value={airConditioning ? airConditioning.acCooling : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Heater</label>
          <input
            type="text"
            className="form-control"
            value={airConditioning ? airConditioning.heater : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Climate Control AC</label>
          <input
            type="text"
            className="form-control"
            value={airConditioning ? airConditioning.climateControlAc : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Rear Defogger</label>
          <input
            type="text"
            className="form-control"
            value={interior ? interior.rearDefogger : ""}
            readOnly={true}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Reverse Camera</label>
          <input
            type="text"
            className="form-control"
            value={interior ? interior.reverseCamera : ""}
            readOnly={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
