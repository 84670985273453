import React from "react";
import { connect } from "react-redux";
import * as StockHelper from "./../../helper/stockHelper";

class BasicDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let { detail = {} } = this.props;
    let {
      insuranceExpiryDate = "",
      appointmentId = "",
      make = "",
      model = "",
      variant = "",
      manufacturingMonth = "",
      manufacturingYear = "",
      odometerReading = "",
      city = "",
      registrationNumber = "",
      fuelType = ""
    } = detail;
    const dateString = insuranceExpiryDate;
    let insExpDate = StockHelper.formatDateString(dateString, "YYYY-MM-DD");

    return (
      <div className="boxs">
        <div className="box-header">
          <h3 className="box-title">Generic</h3>
        </div>
        <div className="box-body col-md-12">
          <div className="form-group col-md-3">
            <label>Appointment ID</label>
            <input
              type="text"
              className="form-control"
              value={appointmentId}
              readOnly={true}
            />
          </div>
          <div className="form-group col-md-3">
            <label>Make</label>
            <input
              type="text"
              className="form-control"
              value={make}
              readOnly={true}
            />
          </div>
          <div className="form-group col-md-3">
            <label>Model</label>
            <input
              type="text"
              className="form-control"
              value={model}
              readOnly={true}
            />
          </div>
          <div className="form-group col-md-3">
            <label>Variant</label>
            <input
              type="text"
              className="form-control"
              value={variant}
              readOnly={true}
            />
          </div>
          <div className="form-group col-md-3">
            <label>Manufacturing Year</label>
            <input
              type="text"
              className="form-control"
              value={manufacturingYear}
              readOnly={true}
            />
          </div>
          <div className="form-group col-md-3">
            <label>Manufacturing Month</label>
            <input
              type="text"
              className="form-control"
              value={manufacturingMonth}
              readOnly={true}
            />
          </div>
          {/* <div className="form-group col-md-3">
            <label>Registration State</label>
            <input
              type="text"
              className="form-control"
              value={this.props.detail.order ? this.props.detail.order.registrationState : ""}
              readOnly={true}
            />
          </div>
          <div className="form-group col-md-3">
            <label>Registration City</label>
            <input
              type="text"
              className="form-control"
              value={this.props.detail.order ? this.props.detail.order.registrationCity : ""}
              readOnly={true}
            />
          </div> */}
          <div className="form-group col-md-3">
            <label>Inspection City</label>
            <input
              type="text"
              className="form-control"
              value={city}
              readOnly={true}
            />
          </div>
          <div className="form-group col-md-3">
            <label>Registration Number</label>
            <input
              type="text"
              className="form-control"
              value={registrationNumber}
              readOnly={true}
            />
          </div>
          <div className="form-group col-md-3">
            <label>Fuel Type</label>
            <input
              type="text"
              className="form-control"
              value={fuelType}
              readOnly={true}
            />
          </div>
          <div className="form-group col-md-3">
            <label>Insurance Expiry</label>
            <input
              type="text"
              className="form-control"
              value={insExpDate}
              readOnly={true}
            />
          </div>
          <div className="form-group col-md-3">
            <label>Odometer</label>
            <input
              type="text"
              className="form-control"
              value={odometerReading}
              readOnly={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToprops = ({
  order: {
    order: { detail }
  }
}) => ({
  detail
});

export default connect(mapStateToprops)(BasicDetails);
